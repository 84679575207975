.invoice {
  background-color: #f4f5f9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

/* Style the select dropdown container */
.invoice .select-container {
  position: relative;
  display: inline-block;
  /* border: 1px solid #BEC0CA;  */
  /* border-radius: 4px; Add rounded corners */
  padding: 5px;
}

/* Styles for the dropdown */
.invoice .dropdown-1 {
  position: absolute;
  top: 100%;
  /* left: 240px; */
  display: none;
  width: 191px;
  background-color: white;
  border: 1px solid #bec0ca;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.invoice .dropdown-1 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.invoice .dropdown-1 li {
  padding: 8px 12px;
  cursor: pointer;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
  display: flex;
}

/* Show the dropdown when the Bulk icon is clicked */
.invoice .label-container-wrapper:hover .dropdown-1 {
  display: block;
}

.invoice .dropdown-1 input[type="checkbox"] {
  margin-right: 8px;
  vertical-align: middle;
}

.invoice li:hover {
  background-color: rgba(190, 192, 202, 1);
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  /* color: var(--black-1); */
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.invoice input[type="date"]::-webkit-calendar-picker-indicator {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M2.06177 6.7695H13.9444" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.9614 9.37313H10.9676" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.00307 9.37313H8.00925" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.03859 9.37313H5.04477" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.9614 11.9641H10.9676" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.00307 11.9641H8.00925" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.03859 11.9641H5.04477" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.6958 1.83333V4.02719" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.31033 1.83333V4.02719" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M10.8255 2.88613H5.18064C3.22285 2.88613 2 3.97675 2 5.98148V12.0146C2 14.0508 3.22285 15.1667 5.18064 15.1667H10.8193C12.7833 15.1667 14 14.0697 14 12.065V5.98148C14.0062 3.97675 12.7895 2.88613 10.8255 2.88613Z" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    no-repeat;
    cursor: pointer;
}

.invoice .text-wrappers{
  position: relative;
  
width: 125px;
  font-family: var(--input-placeholder-font-family);
  font-weight: var(--input-placeholder-font-weight);
  color: var(--black-90);
  font-style: var(--input-placeholder-font-style);
  outline: none;
  border: none;
  background: transparent;
}

.invoice input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  flex-direction: row-reverse;
  border: none;
  gap: 15px;
  cursor: pointer;

  /* Optional: Add padding or other styles as needed */
}

.invoice .div {
  background-color: var(--main-background);
  width: 1440px;
  height: 1024px;
  position: relative;
}

.invoice .main-container {
  position: absolute;
  width: 1352px;
  height: 1024px;
  top: 0;
  left: 88px;
}

.invoice .top-nav {
  display: flex;
  flex-direction: column;
  width: 1352px;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
}

.invoice .nav-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 14px 21px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff;
}

.invoice .nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.invoice .text-wrapper {
  font-family: var(--subheading-3-medium-font-family);
  font-weight: var(--subheading-3-medium-font-weight);
  color: var(--black-60);
  font-size: var(--subheading-3-medium-font-size);
  position: relative;
  width: fit-content;
  letter-spacing: var(--subheading-3-medium-letter-spacing);
  line-height: var(--subheading-3-medium-line-height);
  font-style: var(--subheading-3-medium-font-style);
}

.invoice .profile {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.invoice .iconly-bulk {
  position: relative;
  width: 20px;
  height: 20px;
}

.invoice .profile-2 {
  position: relative;
  width: 32px;
  height: 32px;
  background-color: var(--w-1);
  border-radius: 8px;
}

.invoice .breadcrumbs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 20px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: var(--stroke);
}

.invoice .div-2 {
  display: inline-flex;
  align-items: flex-start;
  gap: 11px;
  position: relative;
  flex: 0 0 auto;
}

.invoice .img {
  position: relative;
  width: 16px;
  height: 16px;
}

.invoice .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-30);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.invoice .item {
  opacity: 0;
  display: inline-flex;
  align-items: flex-start;
  gap: 11px;
  position: relative;
  flex: 0 0 auto;
}

.invoice .header {
  display: flex;
  width: 1311px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 99px;
  left: 21px;
  background-color: transparent;
}

.invoice .text-wrapper-3 {
  position: relative;
  width: fit-content;
  font-family: var(--paragraph-1-medium-font-family);
  font-weight: var(--paragraph-1-medium-font-weight);
  color: var(--black-60);
  font-size: var(--paragraph-1-medium-font-size);
  letter-spacing: var(--paragraph-1-medium-letter-spacing);
  line-height: var(--paragraph-1-medium-line-height);
  white-space: nowrap;
  font-style: var(--paragraph-1-medium-font-style);
}

.invoice .buttons {
  display: flex;
  width: 205px;
  height: 36px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 17px 16px;
  position: relative;
  background-color: var(--w-3);
  border-radius: 12px;
}

.invoice .label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex: 1;
  gap:20px;
  flex-grow: 1;
  margin-top: -11px;
  margin-bottom: -11px;
}

.invoice .fi-plus {
  position: relative;
  width: 24px;
  height: 24px;
}

.invoice .text-wrapper-4 {
  position: relative;
  width: fit-content;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.invoice .container {
  display: inline-flex;
  flex-direction: column;
  height: 643px;
  align-items: flex-end;
  gap: 20px;
  position: absolute;
  top: 150px;
  left: 21px;
}

.invoice .table {
  position: relative;
  width: 1311px;
  height: 650px;
  margin-bottom: -7px;
  background-color: #ffffff;
  border-radius: 12px;
}

.invoice .overlap-group {
  position: relative;
  width: 1262px;
  height: 621px;
  top: 22px;
  left: 21px;
}

.invoice .main-container-2 {
  position: absolute;
  width: 1262px;
  height: 621px;
  top: 0;
  left: 0;
}

.invoice .frame {
  display: inline-flex;
  align-items: center;
  gap: 846px;
  position: absolute;
  top: 0;
  left: 0;
}

.invoice .frame-2 {
  display: inline-flex;
  align-items: flex-start;
  gap: 7px;
  position: relative;
  flex: 0 0 auto;
}

.invoice .left {
  display: flex;
  flex-direction: column;
  width: 176px;
  height: 29px;
  align-items: flex-start;
  gap: 10px;
  padding: 8px;
  position: relative;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-1);
}

.invoice .input-content {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
}

.invoice .iconly-light-search {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: -3.5px;
  margin-bottom: -3.5px;
}

.invoice .div-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 8px;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
}

.invoice .text-wrapper-5 {
  position: relative;
  align-self: stretch;
  margin-top: -2px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-2);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.invoice .right {
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
}

.invoice .table-buttons {
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: relative;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-50);
  all: unset;
  box-sizing: border-box;
}

.invoice .label-container-2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
  margin-bottom: -1.5px;
  border: 1px solid var(--black-1);
  border-radius: 5px;
  padding: 8px 10px;
}
.invoice .label-container-3 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  position: relative;
  flex: 0 0 auto;
  margin-bottom: -1.5px;
  border: 1px solid var(--black-1);
  border-radius: 5px;
  padding: 8px 10px;
}

.invoice .text-wrapper-6 {
  position: relative;
  width: fit-content;
  font-family: var(--label-2-regular-font-family);
  font-weight: var(--label-2-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-2-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-2-regular-letter-spacing);
  line-height: var(--label-2-regular-line-height);
  white-space: nowrap;
  font-style: var(--label-2-regular-font-style);
}

.invoice .label-container-wrapper {
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: relative;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-1);
}

.invoice .table-2 {
  position: absolute;
  width: 1262px;
  height: 572px;
  top: 49px;
  left: 0;
}

.invoice .table-header {
  position: absolute;
  width: 1262px;
  height: 52px;
  top: 0;
  left: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--grey);
}

/* Hide the default checkbox */
.invoice .custom-checkbox2 input[type="checkbox"] {
  display: none;
}

/* Create a custom checkbox style */
.invoice .custom-checkbox2 .checkmark2 {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid var(--black-1);
  background-color: white;
  border-radius: 30%;
  margin-right: 10px;
  top: -1px;
  cursor: pointer;
  left: -1px;
}

/* Style the checkbox when it's checked */
.invoice .custom-checkbox2 input[type="checkbox"]:checked + .checkmark2 {
  background-color: #007bff; /* Background color for checked state */
  border-color: #007bff; /* Border color for checked state */
}

/* Create the checkmark */
.invoice .custom-checkbox2 .checkmark2::after {
  content: "☑️"; /* Unicode checkmark character */
  display: none; /* Initially hidden */
  position: absolute;
  top: 50%;
  left: 53%;
  mix-blend-mode: lighten;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255); /* Color of the checkmark */
  font-size: 20px; /* Adjust the size of the checkmark */
}
/* Show the checkmark when the checkbox is checked */
.invoice .custom-checkbox2 input[type="checkbox"]:checked + .checkmark2::after {
  display: block; /* Show the checkmark when checked */
}

.invoice .invoice .group {
  position: relative;
  width: 24px;
  height: 24px;
  top: 14px;
  border-radius: 8px;
  border: 1px solid;
  border-color: #cfd2d4;
}

.invoice .table-row-container {
  height: 480px;
  top: 52px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.invoice .table-row {
  position: absolute;
  width: 1262px;
  height: 48px;
  top: 0;
  left: 0;
}

.invoice .col-contents {
  position: relative;
  width: 24px;
  height: 24px;
  top: 12px;
  border-radius: 8px;
  border: 1px solid;
  border-color: #cfd2d4;
}

.invoice .col-contents-wrapper {
  height: 48px;
  top: 48px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.invoice .table-row-2 {
  height: 48px;
  top: 96px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.invoice .table-row-3 {
  height: 48px;
  top: 144px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.invoice .table-row-4 {
  height: 48px;
  top: 192px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.invoice .table-row-5 {
  height: 48px;
  top: 240px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.invoice .table-row-6 {
  height: 48px;
  top: 288px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.invoice .table-row-7 {
  height: 48px;
  top: 336px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.invoice .table-row-8 {
  height: 48px;
  top: 384px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.invoice .table-row-9 {
  height: 48px;
  top: 432px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.invoice .table-header-2 {
  position: absolute;
  width: 1262px;
  height: 40px;
  top: 532px;
  left: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: var(--grey);
}

.invoice .text-wrapper-7 {
  position: absolute;
  top: 10px;
  left: 70px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-20);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .text-wrapper-8 {
  position: absolute;
  top: 11px;
  left: 192px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--one-liquiditytextblack-50);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .frame-3 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: absolute;
  top: 9px;
  left: 1068px;
}

.invoice .icon {
  position: relative;
  flex: 0 0 auto;
  display: inline-flex;
  flex-direction: column;
  height: 23px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 11px;
  background-color: #5e636614;
  border-radius: 8px;
}

.invoice .frame-4 {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.invoice .text-wrapper-9 {
  position: relative;
  width: fit-content;
  margin-top: -0.5px;
  color: var(--black-30);
  font-size: var(--label-1-regular-font-size);
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.invoice .text-wrapper-10 {
  position: relative;
  width: fit-content;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--one-liquiditytextblack-50);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .frame-5 {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 13px;
  left: 1222px;
}

.invoice .frame-wrapper {
  position: absolute;
  top: 9px;
  left: 0;
  display: inline-flex;
  flex-direction: column;
  height: 23px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 11px;
  background-color: #5e636614;
  border-radius: 8px;
}

.invoice .phone {
  position: absolute;
  width: 110px;
  height: 499px;
  top: 67px;
  left: 473px;
}

.invoice .frame-6 {
  display: inline-flex;
  align-items: flex-end;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.invoice .text-wrapper-11 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-90);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .group-2 {
  position: absolute;
  width: 86px;
  height: 449px;
  top: 50px;
  left: 0;
}

.invoice .group-3 {
  position: absolute;
  width: 87px;
  height: 17px;
  top: 0;
  left: 0;
}

.invoice .text-wrapper-12 {
  width: 45px;
  position: absolute;
  top: 0;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .u-copy-alt {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 1px;
  left: 69px;
}

.invoice .group-4 {
  position: absolute;
  width: 87px;
  height: 17px;
  top: 48px;
  left: 0;
}

.invoice .text-wrapper-13 {
  position: absolute;
  top: 0;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .group-5 {
  position: absolute;
  width: 87px;
  height: 17px;
  top: 96px;
  left: 0;
}

.invoice .group-6 {
  position: absolute;
  width: 87px;
  height: 17px;
  top: 144px;
  left: 0;
}

.invoice .group-7 {
  position: absolute;
  width: 87px;
  height: 17px;
  top: 192px;
  left: 0;
}

.invoice .group-8 {
  position: absolute;
  width: 87px;
  height: 17px;
  top: 240px;
  left: 0;
}

.invoice .group-9 {
  position: absolute;
  width: 86px;
  height: 17px;
  top: 288px;
  left: 0;
}

.invoice .u-copy-alt-2 {
  left: 68px;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 1px;
}

.invoice .group-10 {
  position: absolute;
  width: 88px;
  height: 17px;
  top: 336px;
  left: 0;
}

.invoice .u-copy-alt-3 {
  left: 70px;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 1px;
}

.invoice .group-11 {
  position: absolute;
  width: 86px;
  height: 17px;
  top: 384px;
  left: 0;
}

.invoice .group-12 {
  position: absolute;
  width: 87px;
  height: 17px;
  top: 432px;
  left: 0;
}

.invoice .email {
  position: absolute;
  width: 96px;
  height: 499px;
  top: 67px;
  left: 277px;
}

.invoice .group-13 {
  position: absolute;
  width: 49px;
  height: 449px;
  top: 50px;
  left: 2px;
}

.invoice .group-14 {
  position: absolute;
  width: 51px;
  height: 17px;
  top: 0;
  left: 0;
}

.invoice .u-copy-alt-4 {
  left: 33px;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 1px;
}

.invoice .group-15 {
  position: absolute;
  width: 51px;
  height: 17px;
  top: 48px;
  left: 0;
}

.invoice .group-16 {
  position: absolute;
  width: 51px;
  height: 17px;
  top: 96px;
  left: 0;
}

.invoice .group-17 {
  position: absolute;
  width: 51px;
  height: 17px;
  top: 144px;
  left: 0;
}

.invoice .group-18 {
  position: absolute;
  width: 51px;
  height: 17px;
  top: 192px;
  left: 0;
}

.invoice .group-19 {
  position: absolute;
  width: 51px;
  height: 17px;
  top: 240px;
  left: 0;
}

.invoice .group-20 {
  position: absolute;
  width: 51px;
  height: 17px;
  top: 288px;
  left: 0;
}

.invoice .group-21 {
  position: absolute;
  width: 51px;
  height: 17px;
  top: 336px;
  left: 0;
}

.invoice .group-22 {
  position: absolute;
  width: 51px;
  height: 17px;
  top: 384px;
  left: 0;
}

.invoice .group-23 {
  position: absolute;
  width: 51px;
  height: 17px;
  top: 432px;
  left: 0;
}

.invoice .status {
  position: absolute;
  width: 74px;
  height: 503px;
  top: 65px;
  left: 957px;
}

.invoice .status-2 {
  display: flex;
  width: 71px;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 4px 11px;
  position: absolute;
  top: 48px;
  left: 3px;
  background-color: var(--w-2);
  border-radius: 8px;
}

.invoice .text-wrapper-14 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--w-1);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.invoice .status-3 {
  display: flex;
  width: 71px;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 4px 11px;
  position: absolute;
  top: 96px;
  left: 3px;
  background-color: var(--w-2);
  border-radius: 8px;
}

.invoice .status-4 {
  display: flex;
  width: 71px;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 4px 11px;
  position: absolute;
  top: 144px;
  left: 3px;
  background-color: var(--w-2);
  border-radius: 8px;
}

.invoice .status-5 {
  display: flex;
  width: 71px;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 4px 11px;
  position: absolute;
  top: 192px;
  left: 3px;
  background-color: var(--w-2);
  border-radius: 8px;
}

.invoice .status-6 {
  display: flex;
  width: 71px;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 4px 11px;
  position: absolute;
  top: 240px;
  left: 3px;
  background-color: var(--w-2);
  border-radius: 8px;
}

.invoice .status-7 {
  display: flex;
  width: 71px;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 4px 11px;
  position: absolute;
  top: 288px;
  left: 3px;
  background-color: #34ca00;
  border-radius: 8px;
}

.invoice .text-wrapper-15 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: #ffffff;
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.invoice .status-8 {
  display: flex;
  width: 71px;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 4px 11px;
  position: absolute;
  top: 336px;
  left: 3px;
  background-color: #34ca00;
  border-radius: 8px;
}

.invoice .status-9 {
  display: flex;
  width: 71px;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 4px 11px;
  position: absolute;
  top: 384px;
  left: 3px;
  background-color: #34ca00;
  border-radius: 8px;
}

.invoice .status-10 {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 11px;
  position: absolute;
  top: 432px;
  left: 3px;
  background-color: #ff6b00;
  border-radius: 8px;
}

.invoice .status-11 {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 11px;
  position: absolute;
  top: 480px;
  left: 3px;
  background-color: #ff6b00;
  border-radius: 8px;
}

.invoice .customer-since {
  width: 178px;
  left: 699px;
  position: absolute;
  height: 499px;
  top: 67px;
}

.invoice .frame-7 {
  display: inline-flex;
  align-items: flex-end;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.invoice .text-wrapper-16 {
  top: 50px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .text-wrapper-17 {
  top: 98px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .text-wrapper-18 {
  top: 146px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .text-wrapper-19 {
  top: 194px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .text-wrapper-20 {
  top: 242px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .text-wrapper-21 {
  top: 290px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .text-wrapper-22 {
  top: 338px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .text-wrapper-23 {
  top: 386px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .text-wrapper-24 {
  top: 434px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .text-wrapper-25 {
  top: 482px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.invoice .customer-name {
  width: 150px;
  left: 45px;
  position: absolute;
  height: 499px;
  top: 67px;
}

.invoice .summary-row {
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  margin-bottom: -172px;
}

.invoice .side-bar {
  position: absolute;
  width: 88px;
  height: 1024px;
  top: 0;
  left: 0;
  background-color: #ffffff;
}

.invoice .menu-items {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 128px;
  left: 16px;
}

.invoice .active {
  height: 56px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  width: 56px;
}

.invoice .iconly-light {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 16px;
  left: 16px;
}

.invoice .iconly-bulk-user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 20px;
  flex: 0 0 auto;
  border-radius: 12px;
  position: relative;
  width: 56px;
}

.invoice .iconly-bulk-user {
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: -4px;
  margin-right: -4px;
}

.invoice .active-2 {
  height: 56px;
  position: relative;
  width: 56px;
}

.invoice .bottom {
  position: absolute;
  width: 46px;
  height: 46px;
  top: 941px;
  left: 21px;
}
