:root {
  --x-1: rgba(146, 208, 80, 1);
  --x-2: rgba(119, 147, 60, 1);
  --x-3: rgba(255, 192, 0, 1);
  --x-4: rgba(246, 129, 0, 1);
  --x-5: rgba(255, 0, 0, 1);
  --w-1: rgba(221, 221, 221, 1);
  --w-2: rgba(56, 56, 56, 1);
  --main-background: rgba(244, 245, 250, 1);
  --black-60: rgba(69, 70, 78, 1);
  --black-30: rgba(139, 141, 151, 1);
  --stroke: rgba(241, 243, 249, 1);
  --black-2: rgba(171, 175, 177, 1);
  --black-1: rgba(207, 211, 212, 1);
  --black-50: rgba(83, 84, 92, 1);
  --grey: rgba(225, 226, 233, 1);
  --black-20: rgba(166, 168, 177, 1);
  --one-liquiditytextblack-50: rgba(102, 102, 102, 1);
  --black-90: rgba(44, 45, 51, 1);
  --black-40: rgba(110, 112, 121, 1);
  --black-4: rgba(94, 99, 102, 1);
  --subheading-3-medium-font-family: "Poppins-Medium", Helvetica;
  --subheading-3-medium-font-weight: 500;
  --subheading-3-medium-font-size: 20px;
  --subheading-3-medium-letter-spacing: 0px;
  --subheading-3-medium-line-height: normal;
  --subheading-3-medium-font-style: normal;
  --label-1-regular-font-family: "Inter-Regular", Helvetica;
  --label-1-regular-font-weight: 400;
  --label-1-regular-font-size: 12px;
  --label-1-regular-letter-spacing: 0px;
  --label-1-regular-line-height: normal;
  --label-1-regular-font-style: normal;
  --label-2-regular-font-family: "Inter-Regular", Helvetica;
  --label-2-regular-font-weight: 400;
  --label-2-regular-font-size: 11px;
  --label-2-regular-letter-spacing: 0px;
  --label-2-regular-line-height: normal;
  --label-2-regular-font-style: normal;
  --paragraph-2-regular-font-family: "Inter-Regular", Helvetica;
  --paragraph-2-regular-font-weight: 400;
  --paragraph-2-regular-font-size: 14px;
  --paragraph-2-regular-letter-spacing: 0px;
  --paragraph-2-regular-line-height: normal;
  --paragraph-2-regular-font-style: normal;
  --input-placeholder-font-family: "Inter-Regular", Helvetica;
  --input-placeholder-font-weight: 400;
  --input-placeholder-font-size: 16px;
  --input-placeholder-letter-spacing: 0px;
  --input-placeholder-line-height: normal;
  --input-placeholder-font-style: normal;
  --variable-collection-b2: rgba(55, 70, 73, 1);
  --variable-collection-b1: rgba(1, 184, 170, 1);
  --variable-collection-w3: rgba(38, 125, 255, 1);
}
