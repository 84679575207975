.add-customers-contacts {
  background-color: #f4f5f9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

/* Style the select dropdown container */
.add-customers-contacts .select-container {
  position: relative;
  display: inline-block;
  /* border: 1px solid #BEC0CA;  */
  /* border-radius: 4px; Add rounded corners */
  padding: 5px;
}

/* Styles for the dropdown */
.add-customers-contacts .dropdown-1 {
  position: absolute;
  top: 100%;
  /* left: 240px; */
  display: none;
  width: 191px;
  background-color: white;
  border: 1px solid #bec0ca;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.add-customers-contacts .dropdown-1 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.add-customers-contacts .dropdown-1 li {
  padding: 8px 12px;
  cursor: pointer;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
  display: flex;
}

/* Show the dropdown when the Bulk icon is clicked */
.add-customers-contacts .label-container-wrapper:hover .dropdown-1 {
  display: block;
}
.add-customers-contacts .click2 {
  cursor: pointer;
}

.add-customers-contacts .dropdown-1 input[type="checkbox"] {
  margin-right: 8px;
  vertical-align: middle;
}

.add-customers-contacts li:hover {
  background-color: rgba(190, 192, 202, 1);
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  /* color: var(--black-1); */
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.add-customers-contacts input[type="date"]::-webkit-calendar-picker-indicator {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M2.06177 6.7695H13.9444" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.9614 9.37313H10.9676" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.00307 9.37313H8.00925" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.03859 9.37313H5.04477" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.9614 11.9641H10.9676" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.00307 11.9641H8.00925" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.03859 11.9641H5.04477" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.6958 1.83333V4.02719" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.31033 1.83333V4.02719" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M10.8255 2.88613H5.18064C3.22285 2.88613 2 3.97675 2 5.98148V12.0146C2 14.0508 3.22285 15.1667 5.18064 15.1667H10.8193C12.7833 15.1667 14 14.0697 14 12.065V5.98148C14.0062 3.97675 12.7895 2.88613 10.8255 2.88613Z" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    no-repeat;
  cursor: pointer;
}

.add-customers-contacts .text-wrappers {
  position: relative;
  left: 32px;
  width: 125px;
  font-family: var(--input-placeholder-font-family);
  font-weight: var(--input-placeholder-font-weight);
  color: var(--black-90);
  font-style: var(--input-placeholder-font-style);
  outline: none;
  border: none;
  background: transparent;
}

.add-customers-contacts input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  flex-direction: row-reverse;
  border: none;
  gap: 15px;
  cursor: pointer;

  /* Optional: Add padding or other styles as needed */
}

.add-customers-contacts .buttons {
  display: flex;
  width: 437px;
  align-items: flex-start;
  justify-content: space-between;
  position: absolute;
  top: 667px;
  left: 90px;
}

.add-customers-contacts .div-wrapper {
  border: 2px solid;
  border-color: #424242;
  display: flex;
  width: 180px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 17px 16px;
  position: relative;
  border-radius: 12px;
  all: unset;
  box-sizing: border-box;
}

.add-customers-contacts .text-wrapper {
  position: relative;
  flex: 1;
  margin-top: 12px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--w-2);
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  border: 1px solid black;
  padding: 15px;
  width: 181px;
  border-radius: 10px;
}

.add-customers-contacts .label-wrapper {
  background-color: #267cff;
  display: flex;
  width: 180px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 17px 16px;
  position: relative;
  border-radius: 12px;
  all: unset;
  box-sizing: border-box;
}
.add-customers-contacts .label-wrapper:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

.add-customers-contacts .div {
  background-color: var(--main-background);
  width: 1440px;
  height: 1024px;
  position: relative;
}

.add-customers-contacts .form {
  position: absolute;
  width: 616px;
  height: 751px;
  top: 155px;
  left: 795px;
  background-color: #ffffff;
  border-radius: 12px;
  visibility: hidden;
}

.add-customers-contacts .text-wrapper {
  position: relative;
  flex: 1;
  margin-top: 12px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--w-2);
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  border: 1px solid black;
  padding: 15px;
  width: 181px;
  border-radius: 10px;
}

/* Hide the default checkbox */
.add-customers-contacts .custom-checkbox2 input[type="checkbox"] {
  display: none;
}

/* Create a custom checkbox style */
.add-customers-contacts .custom-checkbox2 .checkmark2 {
  /* position: relative; */
  display: inline-block;
  /* width: 24px;
  height: 24px; */
  border: 1px solid var(--black-1);
  background-color: white;
  border-radius: 30%;
  margin-right: 10px;
  /* top: -1px; */
  cursor: pointer;
  /* left: -1px; */
}

/* Style the checkbox when it's checked */
.add-customers-contacts
  .custom-checkbox2
  input[type="checkbox"]:checked
  + .checkmark2 {
  background-color: #007bff; /* Background color for checked state */
  border-color: #007bff; /* Border color for checked state */
}

/* Create the checkmark */
.add-customers-contacts .custom-checkbox2 .checkmark2::after {
  content: "\2713"; /* Unicode checkmark character */
  display: none; /* Initially hidden */
  /* position: absolute;
  top: 50%;
  left: 53%; */
  mix-blend-mode: lighten;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255); /* Color of the checkmark */
  font-size: 20px; /* Adjust the size of the checkmark */
}
/* Show the checkmark when the checkbox is checked */
.add-customers-contacts
  .custom-checkbox2
  input[type="checkbox"]:checked
  + .checkmark2::after {
  display: block; /* Show the checkmark when checked */
}

.add-customers-contacts .text-wrapper-2 {
  position: relative;
  align-self: stretch;
  font-family: var(--input-placeholder-font-family);
  font-weight: var(--input-placeholder-font-weight);
  color: var(--black-90);
  font-size: var(--input-placeholder-font-size);
  letter-spacing: var(--input-placeholder-letter-spacing);
  line-height: var(--input-placeholder-line-height);
  font-style: var(--input-placeholder-font-style);
  outline: none;
  border: none;
  background: transparent;
}

.add-customers-contacts .group {
  /* position: absolute; */
  width: 521px;
  height: 244px;
  /* top: 389px;
  left: 54px; */
}
.add-customers-contacts .input-group {
  position: absolute;
  width: 521px;
  height: 244px;
  top: 389px;
  left: 54px;
}

.add-customers-contacts .frame {
  display: flex;
  flex-direction: column;
  width: 521px;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.add-customers-contacts .input {
  display: flex;
  flex-direction: column;
  width: 502px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.add-customers-contacts .top {
  display: flex;
  flex-direction: column;
  width: 522px;
  height: 52px;
  align-items: flex-start;
  gap: 10px;
  padding: 8px 16px;
  position: relative;
  margin-right: -20px;
  background-color: var(--inputdefaultbackground);
  border-radius: 8px;
}

.add-customers-contacts .input-content {
  justify-content: space-around;
  gap: 20px;
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.add-customers-contacts .text-wrapper-3 {
  position: relative;
  align-self: stretch;
  font-family: var(--input-placeholder-font-family);
  font-weight: var(--input-placeholder-font-weight);
  color: var(--w-2);
  font-size: var(--input-placeholder-font-size);
  letter-spacing: var(--input-placeholder-letter-spacing);
  line-height: var(--input-placeholder-line-height);
  font-style: var(--input-placeholder-font-style);
}

.add-customers-contacts .top-wrapper {
  width: 521px;
  gap: 8px;
  position: absolute;
  top: 128px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.add-customers-contacts .input-content-wrapper {
  height: 52px;
  padding: 8px 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  background-color: var(--inputdefaultbackground);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.add-customers-contacts .text-wrapper-4 {
  position: relative;
  align-self: stretch;
  font-family: var(--input-placeholder-font-family);
  font-weight: var(--input-placeholder-font-weight);
  color: var(--black-2);
  font-size: var(--input-placeholder-font-size);
  letter-spacing: var(--input-placeholder-letter-spacing);
  line-height: var(--input-placeholder-line-height);
  font-style: var(--input-placeholder-font-style);
}

.add-customers-contacts .input-wrapper {
  position: absolute;
  width: 521px;
  height: 52px;
  top: 64px;
  left: 0;
}

.add-customers-contacts .div-wrapper {
  width: 521px;
  gap: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.add-customers-contacts .top-2 {
  width: 521px;
  height: 52px;
  padding: 8px 16px;
  position: relative;
  background-color: var(--inputdefaultbackground);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.add-customers-contacts .select {
  display: flex;
  flex-direction: column;
  width: 521px;
  align-items: flex-start;
  gap: 4px;
  position: absolute;
  top: 192px;
  left: 0;
}

.add-customers-contacts .frame-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.add-customers-contacts .input-content-2 {
  align-items: center;
  gap: 20px;
  align-self: stretch;
  width: 100%;
  display: flex;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.add-customers-contacts .input-content-3 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 8px;
  align-self: stretch;
  display: flex;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.add-customers-contacts .img {
  position: relative;
  width: 24px;
  height: 24px;
}

.add-customers-contacts .frame-3 {
  display: inline-flex;
  align-items: center;
  gap: 25px;
  position: absolute;
  top: 345px;
  left: 54px;
}

.add-customers-contacts .text-wrapper-5 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--paragraph-1-medium-font-family);
  font-weight: var(--paragraph-1-medium-font-weight);
  color: var(--black-30);
  font-size: var(--paragraph-1-medium-font-size);
  letter-spacing: var(--paragraph-1-medium-letter-spacing);
  line-height: var(--paragraph-1-medium-line-height);
  white-space: nowrap;
  font-style: var(--paragraph-1-medium-font-style);
}

.add-customers-contacts .group-2 {
  position: absolute;
  width: 162px;
  height: 24px;
  top: 342px;
  left: 414px;
}

.add-customers-contacts .group-3 {
  position: absolute;
  width: 86px;
  height: 24px;
  top: 0;
  left: 0;
}

.add-customers-contacts .div-2 {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  border-radius: 8px;
  border: 1px solid;
  border-color: var(--black-1);
}

.add-customers-contacts .checkbox-radio {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: 2px;
  left: 35px;
}

.add-customers-contacts .text-wrapper-6 {
  position: relative;
  align-self: stretch;
  font-family: var(--input-placeholder-font-family);
  font-weight: var(--input-placeholder-font-weight);
  color: var(--black-90);
  font-size: var(--input-placeholder-font-size);
  letter-spacing: var(--input-placeholder-letter-spacing);
  line-height: var(--input-placeholder-line-height);
  font-style: var(--input-placeholder-font-style);
  outline: none;
  width: 100%;
  border: none;
  background: transparent;
}

.add-customers-contacts .group-4 {
  position: absolute;
  width: 56px;
  height: 24px;
  top: 0;
  left: 106px;
}

.add-customers-contacts .checkbox-radio-wrapper {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--black-1);
}

/* .add-customers-contacts .control-wrapper {
  height: 24px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid;
  border-color: #6c7dcd;
} */

/* .add-customers-contacts .control {
  position: relative;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 2px;
  background-color: var(--variable-collection-w3);
  border-radius: 6px;
} */

.add-customers-contacts .fi-check {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 4px;
  left: 4px;
}

.add-customers-contacts .checkbox-radio-2 {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: 4px;
  left: 34px;
}
.btn {
  /* border: none; */
}
.add-customers-contacts .text-wrapper-7 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-5);
  font-size: 14px;
  text-align: right;
  letter-spacing: 0;
  line-height: normal;
}

.add-customers-contacts .overlap-group {
  position: absolute;
  width: 532px;
  height: 52px;
  top: 264px;
  left: 54px;
}

.add-customers-contacts .frame-4 {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 8px;
  left: 486px;
  cursor: pointer;
}

.add-customers-contacts .element {
  display: flex;
  flex-direction: column;
  width: 490px;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.add-customers-contacts .phone {
  display: flex;
  height: 52px;
  align-items: flex-start;
  gap: 6px;
  position: relative;
  align-self: stretch;
  width: 100%;
}

.add-customers-contacts .country-code {
  display: flex;
  flex-direction: column;
  width: 97px;
  align-items: flex-start;
  gap: 10px;
  padding: 8px 16px;
  position: relative;
  align-self: stretch;
  background-color: var(--inputdefaultbackground);
  border-radius: 8px;
}

.add-customers-contacts .input-content-4 {
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  display: flex;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.add-customers-contacts .frame-5 {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 1;
  flex-grow: 1;
  margin-top: -3px;
  margin-bottom: -3px;
}

.add-customers-contacts .input-content-5 {
  flex-direction: column;
  height: 42px;
  align-items: flex-start;
  justify-content: space-around;
  gap: 8px;
  display: flex;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.add-customers-contacts .iconly-light-arrow {
  position: relative;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
}

.add-customers-contacts .input-2 {
  width: 369px;
  gap: 10px;
  padding: 8px 16px;
  position: relative;
  align-self: stretch;
  background-color: var(--inputdefaultbackground);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.add-customers-contacts .element-input {
  display: flex;
  flex-direction: column;
  width: 522px;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 197px;
  left: 54px;
}

.add-customers-contacts .element-input-2 {
  display: flex;
  flex-direction: column;
  width: 522px;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 130px;
  left: 54px;
}

.add-customers-contacts .text-wrapper-8 {
  position: absolute;
  top: 94px;
  left: 53px;
  font-family: var(--paragraph-1-medium-font-family);
  font-weight: var(--paragraph-1-medium-font-weight);
  color: var(--black-30);
  font-size: var(--paragraph-1-medium-font-size);
  letter-spacing: var(--paragraph-1-medium-letter-spacing);
  line-height: var(--paragraph-1-medium-line-height);
  white-space: nowrap;
  font-style: var(--paragraph-1-medium-font-style);
}

.add-customers-contacts .edit-cross {
  display: flex;
  flex-direction: column;
  width: 532px;
  height: 32px;
  align-items: flex-start;
  justify-content: space-around;
  gap: 13px;
  position: absolute;
  top: 44px;
  left: 54px;
}

.add-customers-contacts .div-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.add-customers-contacts .text-wrapper-9 {
  position: relative;
  width: fit-content;
  font-family: var(--subheading-3-medium-font-family);
  font-weight: var(--subheading-3-medium-font-weight);
  color: #000000;
  font-size: var(--subheading-3-medium-font-size);
  text-align: center;
  letter-spacing: var(--subheading-3-medium-letter-spacing);
  line-height: var(--subheading-3-medium-line-height);
  font-style: var(--subheading-3-medium-font-style);
}

.add-customers-contacts .frame-6 {
  position: relative;
  width: 32px;
  height: 32px;
}

.add-customers-contacts .list-of-contacts-and {
  position: absolute;
  width: 371px;
  height: 848px;
  top: 155px;
  left: 411px;
  background-color: #ffffff;
  border-radius: 12px;
}
.add-customers-contacts .contact-list {
  height: 350px;
  overflow: scroll;
}
.add-customers-contacts .info-list {
  text-align: center;
}

.add-customers-contacts .frame-wrapper {
  /* position: absolute; */
  width: 155px;
  height: 30px;
  /* top: 35px;
  left: 19px; */
}

.add-customers-contacts .frame-wrapper2 {
  position: absolute;
  width: 1314px;
  height: 71px;
  top: 67px;
  left: 109px;
  background-color: #ffffff;
  border-radius: 12px;
}

.add-customers-contacts .frame-7 {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 20px 15px 20px 15px;

  /* gap: 361px; */
  /* position: relative; */
}

.add-customers-contacts .text-wrapper-10 {
  /* position: relative; */
  flex-grow: 0.9;
  width: fit-content;
  font-family: var(--subheading-3-medium-font-family);
  font-weight: var(--subheading-3-medium-font-weight);
  color: #000000;
  font-size: var(--subheading-3-medium-font-size);
  text-align: start;
  letter-spacing: var(--subheading-3-medium-letter-spacing);
  line-height: var(--subheading-3-medium-line-height);
  font-style: var(--subheading-3-medium-font-style);
}

.add-customers-contacts .icon {
  display: inline-flex;
  flex-direction: column;
  height: 23px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 11px;
  position: absolute;
  top: 748px;
  left: 160px;
  background-color: #5e636614;
  border-radius: 8px;
}

.add-customers-contacts .frame-8 {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.add-customers-contacts .text-wrapper-11 {
  position: relative;
  width: fit-content;
  margin-top: -0.5px;
  color: var(--black-30);
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.add-customers-contacts .img-2 {
  position: relative;
  width: 16px;
  height: 16px;
}

.add-customers-contacts .text-wrapper-12 {
  position: absolute;
  top: 750px;
  left: 220px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--one-liquiditytextblack-50);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.add-customers-contacts .actions-arrow-down {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 752px;
  left: 314px;
}

.add-customers-contacts .actions-arrow-down-2 {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 752px;
  left: 338px;
}

.add-customers-contacts .line {
  width: 371px;
  height: 1px;
  top: 229px;
  left: 0;
  position: absolute;
  object-fit: cover;
}

.add-customers-contacts .line-2 {
  width: 371px;
  height: 1px;
  top: 273px;
  left: -1px;
  position: absolute;
  object-fit: cover;
}

.add-customers-contacts .line-3 {
  width: 371px;
  height: 1px;
  top: 321px;
  left: -1px;
  position: absolute;
  object-fit: cover;
}

.add-customers-contacts .line-4 {
  width: 371px;
  height: 1px;
  top: 369px;
  left: -1px;
  position: absolute;
  object-fit: cover;
}

.add-customers-contacts .group-5 {
  position: absolute;
  width: 343px;
  height: 34px;
  top: 235px;
  left: 23px;
}

.add-customers-contacts .group-wrapper {
  position: absolute;
  width: 126px;
  height: 24px;
  top: 5px;
  left: 0;
}

.add-customers-contacts .group-6 {
  position: relative;
  width: 128px;
  height: 24px;
}

.add-customers-contacts .text-wrapper-13 {
  position: absolute;
  top: 3px;
  left: 44px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.add-customers-contacts .fi-x {
  /* position: absolute; */
  width: 34px;
  height: 34px;
  /* top: 0;
  left: 309px; */
  cursor: pointer;
}

.add-customers-contacts .group-7 {
  position: absolute;
  width: 343px;
  height: 34px;
  top: 283px;
  left: 23px;
}

.add-customers-contacts .group-8 {
  position: absolute;
  width: 136px;
  height: 24px;
  top: 5px;
  left: 0;
}

.add-customers-contacts .group-9 {
  position: relative;
  width: 138px;
  height: 24px;
}

.add-customers-contacts .group-10 {
  position: absolute;
  width: 343px;
  height: 34px;
  top: 331px;
  left: 23px;
}

.add-customers-contacts .group-11 {
  position: absolute;
  width: 127px;
  height: 24px;
  top: 5px;
  left: 0;
}

.add-customers-contacts .group-12 {
  position: relative;
  width: 129px;
  height: 24px;
}

.add-customers-contacts .overlap {
  /* position: absolute; */
  width: 373px;
  /* top: 86px;
  left: 0; */
}

.add-customers-contacts .line-5 {
  width: 1px;
  height: 6px;
  /* top: 83px;
  left: 355px;
  position: absolute; */
  object-fit: cover;
}

.add-customers-contacts .line-6 {
  width: 1px;
  height: 6px;
  top: 109px;
  left: 355px;
  position: absolute;
  object-fit: cover;
}

.add-customers-contacts .contact-one {
  /* position: absolute; */
  width: 373px;
  /* top: 0;
  left: 0; */
}

.add-customers-contacts .col-contents {
  /* position: absolute; */

  width: 24px;
  height: 24px;
  /* top: 0;
  left: 24px; */
  border-radius: 8px;
  border: 1px solid;
  border-color: var(--black-1);
}
.add-customers-contacts .contact-tile-head {
  display: inline-flex;
  flex-grow: 0.9;
  gap: 10px;
  align-items: center;
}
.contact-collapse-group {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin: 10px 10px 0px 15px;
}

.add-customers-contacts .text-wrapper-14 {
  /* position: absolute;
  top: 3px;
  left: 68px; */
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.add-customers-contacts .fi-x-2 {
  width: 34px;
  height: 34px;
  /* left: 337px;
  position: absolute;
  top: 0; */
  cursor: pointer;
}
.add-customers-contacts .contact-details {
  display: flex;
  flex-direction: row;
}

.add-customers-contacts .edit {
  /* position: absolute; */
  width: 19px;
  height: 19px;
  /* top: 45px;
  left: 338px; */
  cursor: pointer;
}

.add-customers-contacts .link-to-each-other {
  /* position: absolute; */
  width: 24px;
  height: 34px;
  /* top: 82px;
  left: 342px; */
}

.add-customers-contacts .contact-one-details {
  /* position: absolute; */

  height: 71px;
  margin: 10px 20px 10px 20px;
  /* top: 50px;
  left: 28px; */
}

.add-customers-contacts .group-13 {
  /* position: absolute; */
  display: flex;
  /* justify-content: space-between; */
  width: 280px;
  height: 17px;
  gap: 30px;
  /* top: 0;
  left: 0; */
}

.add-customers-contacts .text-wrapper-15 {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 138px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.add-customers-contacts .text-wrapper-16 {
  /* position: absolute;
  top: 0;
  left: 172px; */
  width: 110px;
  font-family: "Inter", Helvetica;
  font-weight: 500;
  color: var(--w-2);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  overflow: scroll;
}

.add-customers-contacts .group-14 {
  /* position: absolute; */
  display: flex;
  width: 280px;
  height: 17px;
  /* justify-content: space-between; */
  gap: 30px;
  margin: 10px 0px 10px 0px;
  /* border: 1px solid; */
  /* top: 27px;
  left: 0; */
}
.add-customers-contacts .edit-and-link {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 270px;
  height: 100;
}

.add-customers-contacts .group-15 {
  /* position: absolute; */
  /* justify-content: space-between; */
  display: flex;
  gap: 30px;
  width: 300px;
  height: 17px;
  /* top: 54px;
  left: 0; */
}

.add-customers-contacts .line-7 {
  width: 371px;
  height: 1px;
  /* top: 33px;
  left: 0;
  position: absolute; */
  object-fit: cover;
}

.add-customers-contacts .group-16 {
  position: absolute;
  width: 145px;
  height: 30px;
  top: 401px;
  left: 16px;
}

.add-customers-contacts .frame-9 {
  width: 145px;
  display: flex;
  align-items: center;
  gap: 361px;
  position: relative;
}

.add-customers-contacts .group-17 {
  position: absolute;
  width: 345px;
  height: 34px;
  top: 448px;
  left: 21px;
}

.add-customers-contacts .fi-x-3 {
  width: 34px;
  height: 34px;
  left: 311px;
  position: absolute;
  top: 0;
}

.add-customers-contacts .group-18 {
  position: absolute;
  width: 132px;
  height: 24px;
  top: 4px;
  left: 0;
}

.add-customers-contacts .group-19 {
  position: absolute;
  width: 345px;
  height: 34px;
  top: 496px;
  left: 21px;
}

.add-customers-contacts .group-20 {
  position: absolute;
  width: 130px;
  height: 24px;
  top: 4px;
  left: 0;
}

.add-customers-contacts .group-21 {
  position: absolute;
  width: 345px;
  height: 34px;
  top: 544px;
  left: 21px;
}

.add-customers-contacts .group-22 {
  position: absolute;
  width: 140px;
  height: 24px;
  top: 4px;
  left: 0;
}

.add-customers-contacts .group-23 {
  position: absolute;
  width: 345px;
  height: 34px;
  top: 591px;
  left: 21px;
}

.add-customers-contacts .group-24 {
  position: absolute;
  width: 131px;
  height: 24px;
  top: 5px;
  left: 0;
}

.add-customers-contacts .group-25 {
  position: absolute;
  width: 345px;
  height: 34px;
  top: 639px;
  left: 21px;
}

.add-customers-contacts .group-26 {
  position: absolute;
  width: 129px;
  height: 24px;
  top: 5px;
  left: 0;
}

.add-customers-contacts .line-8 {
  top: 677px;
  position: absolute;
  width: 371px;
  height: 1px;
  left: -3px;
  object-fit: cover;
}

.add-customers-contacts .line-9 {
  top: 485px;
  position: absolute;
  width: 371px;
  height: 1px;
  left: -3px;
  object-fit: cover;
}

.add-customers-contacts .line-10 {
  width: 371px;
  height: 1px;
  top: 533px;
  left: -3px;
  position: absolute;
  object-fit: cover;
}

.add-customers-contacts .line-11 {
  width: 371px;
  height: 1px;
  top: 581px;
  left: -3px;
  position: absolute;
  object-fit: cover;
}

.add-customers-contacts .line-12 {
  width: 371px;
  height: 1px;
  top: 629px;
  left: -3px;
  position: absolute;
  object-fit: cover;
}

.add-customers-contacts .group-27 {
  position: absolute;
  width: 345px;
  height: 34px;
  top: 686px;
  left: 21px;
}

.add-customers-contacts .group-28 {
  position: absolute;
  width: 123px;
  height: 24px;
  top: 6px;
  left: 0;
}

.add-customers-contacts .line-13 {
  position: absolute;
  width: 371px;
  height: 1px;
  top: 725px;
  left: -3px;
  object-fit: cover;
}

.add-customers-contacts .frame-10 {
  /* position: absolute; */
  width: 35px;
  height: 35px;
  /* top: 35px;
  left: 320px; */
  cursor: pointer;
}

.add-customers-contacts .table {
  position: absolute;
  width: 266px;
  height: 848px;
  top: 155px;
  left: 130px;
  background-color: #ffffff;
  border-radius: 12px;
}

.add-customers-contacts .company-list {
  position: absolute;
  width: 219px;
  height: 744px;
  top: 65px;
  left: 21px;
}

.add-customers-contacts .group-29 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 0;
  left: 0;
}

.add-customers-contacts .text-wrapper-17 {
  position: absolute;
  top: 0;
  left: 185px;
  color: var(--black-40);
  font-size: 14px;
  text-decoration: underline;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.add-customers-contacts .text-wrapper-18 {
  position: absolute;
  top: 2px;
  left: 46px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.add-customers-contacts .group-30 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 48px;
  left: 0;
}

.add-customers-contacts .text-wrapper-19 {
  position: absolute;
  top: 1px;
  left: 185px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.add-customers-contacts .group-31 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 96px;
  left: 0;
}

.add-customers-contacts .group-32 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 144px;
  left: 0;
}

.add-customers-contacts .group-33 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 192px;
  left: 0;
}

.add-customers-contacts .group-34 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 240px;
  left: 0;
}

.add-customers-contacts .group-35 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 288px;
  left: 0;
}

.add-customers-contacts .group-36 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 336px;
  left: 0;
}

.add-customers-contacts .group-37 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 384px;
  left: 0;
}

.add-customers-contacts .group-38 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 432px;
  left: 0;
}

.add-customers-contacts .group-39 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 480px;
  left: 0;
}

.add-customers-contacts .text-wrapper-20 {
  position: absolute;
  top: 4px;
  left: 185px;
  color: var(--black-40);
  font-size: 14px;
  text-decoration: underline;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.add-customers-contacts .text-wrapper-21 {
  top: 6px;
  position: absolute;
  left: 46px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.add-customers-contacts .group-40 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 528px;
  left: 0;
}

.add-customers-contacts .group-41 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 576px;
  left: 0;
}

.add-customers-contacts .group-42 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 624px;
  left: 0;
}

.add-customers-contacts .group-43 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 672px;
  left: 0;
}

.add-customers-contacts .group-44 {
  position: absolute;
  width: 223px;
  height: 24px;
  top: 720px;
  left: 0;
}

.add-customers-contacts .company-name-heading {
  display: inline-flex;
  align-items: flex-end;
  gap: 8px;
  position: absolute;
  top: 24px;
  left: 17px;
}

.add-customers-contacts .text-wrapper-22 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-90);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

/* .add-customers-contacts .bar {
  position: absolute;
  width: 1314px;
  height: 71px;
  top: 67px;
  left: 109px;
  background-color: #ffffff;
  border-radius: 12px;
} */

.add-customers-contacts .bar {
  display: flex;
  gap: 9px;
  /* row-gap: 42px; */
  position: relative;
  justify-content: start;
  top: 20px;
  left: 18px;
}

.add-customers-contacts .search-filter-bulk {
  display: inline-flex;
  align-items: flex-start;
  gap: 7px;
  position: relative;
  top: 22px;
  left: 21px;
  z-index: 10;
}

.add-customers-contacts .left {
  display: flex;
  flex-direction: column;
  width: 183px;
  height: 29px;
  align-items: flex-start;
  gap: 10px;
  padding: 8px;
  position: relative;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-1);
}

.add-customers-contacts .iconly-light-search {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: -3.5px;
  margin-bottom: -3.5px;
}

.add-customers-contacts .text-wrapper-23 {
  position: relative;
  align-self: stretch;
  margin-top: -2px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-2);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.add-customers-contacts .right {
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
}

.add-customers-contacts .table-buttons {
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: relative;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-50);
  all: unset;
  box-sizing: border-box;
}

.add-customers-contacts .label-container {
  display: flex;
  gap: 20px;
  left: 105px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}
.add-customers-contacts .label-container-1 {
  display: flex;

  left: 105px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}

.add-customers-contacts .text-wrapper-24 {
  position: relative;
  width: fit-content;
  font-family: var(--label-2-regular-font-family);
  font-weight: var(--label-2-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-2-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-2-regular-letter-spacing);
  line-height: var(--label-2-regular-line-height);
  white-space: nowrap;
  font-style: var(--label-2-regular-font-style);
}

.add-customers-contacts .side-bar {
  position: absolute;
  width: 88px;
  height: 1024px;
  top: 0;
  left: 0;
  background-color: #ffffff;
}

.add-customers-contacts .overlap-2 {
  position: relative;
  height: 1024px;
}

.add-customers-contacts .menu-items {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 128px;
  left: 16px;
}

.add-customers-contacts .active {
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 12px;
  overflow: hidden;
}

.add-customers-contacts .img-3 {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 16px;
  left: 16px;
}

.add-customers-contacts .overlap-group-2 {
  position: relative;
  width: 40px;
  height: 39px;
  top: 1px;
  left: 16px;
}

.add-customers-contacts .img-4 {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 15px;
  left: 0;
}

.add-customers-contacts .frame-11 {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 16px;
  background-color: #424242;
  border-radius: 500px;
  overflow: hidden;
}

.add-customers-contacts .text-wrapper-25 {
  position: absolute;
  top: 3px;
  left: 8px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--w-1);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.add-customers-contacts .iconly-bulk-user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 20px;
  flex: 0 0 auto;
  background-color: #424242;
  border-radius: 12px;
  width: 56px;
  position: relative;
}

.add-customers-contacts .iconly-bulk-user {
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: -4px;
  margin-right: -4px;
}

.add-customers-contacts .bar {
  display: flex;
  gap: 9px;
  /* row-gap: 42px; */
  position: relative;
  justify-content: start;
  top: 20px;
  left: 18px;
}
.add-customers-contacts .search {
  display: flex;
  gap: 20px;

  left: 9px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}

.add-customers-contacts .filters {
  display: flex;
  gap: 20px;

  left: 105px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}
.add-customers-contacts .filter-2 {
  display: flex;
  left: 190px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}
.add-customers-contacts .frame-wrapper-1 {
  position: absolute;
  width: 1314px;
  height: 71px;
  top: 67px;
  left: 109px;
  background-color: #ffffff;
  border-radius: 12px;
}
.add-customers-contacts .icn {
  position: relative;
  width: 16px;
  height: 16px;
  top: 2px;
}
.add-customers-contacts .label {
  width: fit-content;
  font-family: var(--label-2-regular-font-family);
  font-weight: var(--label-2-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-2-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-2-regular-letter-spacing);
  line-height: var(--label-2-regular-line-height);
  white-space: nowrap;
  font-style: var(--label-2-regular-font-style);
}
.add-customers-contacts .label1 {
  position: relative;
  flex: 1;
  margin-top: 12px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--w-2);
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  border: 1px solid black;
  padding: 15px;
  width: 182px;
  border-radius: 10px;
  background-color: #267cff;
  color: #e0e2e8;
}
.add-customers-contacts .bulk-actions {
  display: flex;
  gap: 6px;
  left: 274px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}

.add-customers-contacts .text-wrapper-26 {
  position: absolute;
  top: 3px;
  left: 5px;
  color: var(--w-1);
  text-align: center;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.add-customers-contacts .bottom {
  position: absolute;
  width: 46px;
  height: 46px;
  top: 828px;
  left: 8px;
}

.add-customers-contacts .logout {
  display: flex;
  flex-direction: column;
  width: 56px;
  align-items: flex-start;
  gap: 10px;
  padding: 11px 16px;
  position: absolute;
  top: 846px;
  left: 16px;
  background-color: #5e63661a;
  border-radius: 16px;
}

.add-customers-contacts .fi-headphones-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
}

.add-customers-contacts .img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 20px;
  flex: 0 0 auto;
  background-color: var(--w-2);
  border-radius: 12px;
  width: 56px;
  position: relative;
}

.add-customers-contacts .active-2 {
  height: 56px;
  width: 56px;
  position: relative;
}

.add-customers-contacts .top-nav {
  width: 1352px;
  padding: 14px 21px;
  position: absolute;
  top: 0;
  left: 88px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.add-customers-contacts .text-wrapper-27 {
  position: relative;
  width: fit-content;
  font-family: var(--subheading-3-medium-font-family);
  font-weight: var(--subheading-3-medium-font-weight);
  color: var(--black-60);
  font-size: var(--subheading-3-medium-font-size);
  letter-spacing: var(--subheading-3-medium-letter-spacing);
  line-height: var(--subheading-3-medium-line-height);
  font-style: var(--subheading-3-medium-font-style);
}

.add-customers-contacts .profile {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.add-customers-contacts .iconly-bulk {
  position: relative;
  width: 20px;
  height: 20px;
}

.add-customers-contacts .profile-2 {
  position: relative;
  width: 32px;
  height: 32px;
  background-color: var(--w-1);
  border-radius: 8px;
}
