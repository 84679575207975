.element-customers {
  background-color: #f4f5f9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

/* Style the select dropdown container */
.element-customers .select-container {
  position: relative;
  display: inline-block;
  /* border: 1px solid #BEC0CA;  */
  /* border-radius: 4px; Add rounded corners */
  padding: 5px;
}

/* Styles for the dropdown */
.element-customers .dropdown-1 {
  position: absolute;
  top: 100%;
  /* left: 240px; */
  display: none;
  width: 191px;
  background-color: white;
  border: 1px solid #bec0ca;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.element-customers .dropdown-1 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.element-customers .dropdown-1 li {
  padding: 8px 12px;
  cursor: pointer;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
  display: flex;
}

/* Show the dropdown when the Bulk icon is clicked */
.element-customers .bulk-actions:hover .dropdown-1 {
  display: block;
}

.element-customers .dropdown-1 input[type="checkbox"] {
  margin-right: 8px;
  vertical-align: middle;
}

.element-customers li:hover {
  background-color: rgba(190, 192, 202, 1);
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  /* color: var(--black-1); */
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.element-customers input[type="date"]::-webkit-calendar-picker-indicator {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M2.06177 6.7695H13.9444" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.9614 9.37313H10.9676" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.00307 9.37313H8.00925" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.03859 9.37313H5.04477" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.9614 11.9641H10.9676" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.00307 11.9641H8.00925" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.03859 11.9641H5.04477" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.6958 1.83333V4.02719" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.31033 1.83333V4.02719" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M10.8255 2.88613H5.18064C3.22285 2.88613 2 3.97675 2 5.98148V12.0146C2 14.0508 3.22285 15.1667 5.18064 15.1667H10.8193C12.7833 15.1667 14 14.0697 14 12.065V5.98148C14.0062 3.97675 12.7895 2.88613 10.8255 2.88613Z" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    no-repeat;
  cursor: pointer;
}

.element-customers input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  flex-direction: row-reverse;
  border: none;
  gap: 15px;
  cursor: pointer;

  /* Optional: Add padding or other styles as needed */
}

/* Hide the default checkbox */
.element-customers .custom-checkbox2 input[type="checkbox"] {
  display: none;
}

/* Create a custom checkbox style */
.element-customers .custom-checkbox2 .checkmark2 {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid var(--black-1);
  background-color: white;
  border-radius: 30%;
  margin-right: 10px;
  top: -1px;
  cursor: pointer;
  left: -1px;
}

/* Style the checkbox when it's checked */
.element-customers
  .custom-checkbox2
  input[type="checkbox"]:checked
  + .checkmark2 {
  background-color: #007bff; /* Background color for checked state */
  border-color: #007bff; /* Border color for checked state */
}

/* Create the checkmark */
.element-customers .custom-checkbox2 .checkmark2::after {
  content: "\2713"; /* Unicode checkmark character */
  display: none; /* Initially hidden */
  position: absolute;
  top: 50%;
  left: 53%;
  mix-blend-mode: lighten;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255); /* Color of the checkmark */
  font-size: 20px; /* Adjust the size of the checkmark */
}
/* Show the checkmark when the checkbox is checked */
.element-customers.custom-checkbox2
  input[type="checkbox"]:checked
  + .checkmark2::after {
  display: block; /* Show the checkmark when checked */
}

.element-customers .div {
  background-color: var(--main-background);
  width: 1440px;
  height: 1024px;
  position: relative;
}

.element-customers .form {
  position: absolute;
  width: 1000px;
  height: 836px;
  top: 155px;
  left: 417px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 100px #00000026;
}

.element-customers .buttons {
  display: flex;
  width: 701px;
  align-items: flex-start;
  justify-content: space-between;
  position: absolute;
  top: 726px;
  left: 150px;
}

.element-customers .div-wrapper {
  border: 2px solid;
  border-color: #424242;
  display: flex;
  width: 180px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 17px 16px;
  position: relative;
  border-radius: 12px;
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
}

.element-customers .text-wrapper {
  position: relative;
  flex: 1;
  margin-top: 12px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--w-2);
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  border: 1px solid black;
  padding: 15px;
  width: 181px;
  border-radius: 10px;
}

.element-customers .label-wrapper {
  background-color: #267cff;
  display: flex;
  width: 180px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 17px 16px;
  position: relative;
  border-radius: 12px;
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
}
.element-customers .label-wrapper:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

.element-customers .overlap-group {
  position: absolute;
  width: 704px;
  height: 277px;
  top: 435px;
  left: 150px;
}

.element-customers .add-address {
  position: absolute;
  width: 704px;
  height: 275px;
  top: 2px;
  left: 0;
}

.element-customers .inputs {
  position: absolute;
  width: 702px;
  height: 238px;
  top: 37px;
  left: 0;
}

.element-customers .add-button {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 195px;
  left: 666px;
}

.element-customers .state {
  display: flex;
  flex-direction: column;
  width: 650px;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 186px;
  left: 0;
}

.element-customers .top {
  display: flex;
  flex-direction: column;
  height: 52px;
  align-items: flex-start;
  gap: 10px;
  padding: 8px 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  background-color: var(--inputdefaultbackground);
  border-radius: 8px;
}

.element-customers .input-content {
  align-items: center;
  gap: 16px;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
  display: flex;
  position: relative;
}

.element-customers .input-content-2 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  align-self: stretch;
  display: flex;
  gap: 8px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.element-customers .text-wrapper-2 {
  position: relative;
  align-self: stretch;
  font-family: var(--input-placeholder-font-family);
  font-weight: var(--input-placeholder-font-weight);
  color: var(--black-90);
  font-size: var(--input-placeholder-font-size);
  letter-spacing: var(--input-placeholder-letter-spacing);
  line-height: var(--input-placeholder-line-height);
  font-style: var(--input-placeholder-font-style);
  outline: none;
  border: none;
  background: transparent;
}

.element-customers .bar {
  display: flex;
  gap: 9px;
  /* row-gap: 42px; */
  position: relative;
  justify-content: start;
  top: 20px;
  left: 18px;
}
.element-customers .search {
  display: flex;
  gap: 20px;

  left: 9px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}

.element-customers .filters {
  display: flex;
  gap: 20px;

  left: 105px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}
.element-customers .filter-2 {
  display: flex;
  left: 190px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}
.element-customers .icn {
  position: relative;
  width: 16px;
  height: 16px;
  top: 2px;
}
.element-customers .label {
  width: fit-content;
  font-family: var(--label-2-regular-font-family);
  font-weight: var(--label-2-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-2-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-2-regular-letter-spacing);
  line-height: var(--label-2-regular-line-height);
  white-space: nowrap;
  font-style: var(--label-2-regular-font-style);
}
.element-customers .label1 {
  position: relative;
  flex: 1;
  margin-top: 12px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--w-2);
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  border: 1px solid black;
  padding: 15px;
  width: 182px;
  border-radius: 10px;
  background-color: #267cff;
  color: #e0e2e8;
}
.element-customers .bulk-actions {
  display: flex;
  gap: 20px;
  left: 274px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}

.element-customers .text-wrappers {
  position: relative;
  width: 125px;
  font-family: var(--input-placeholder-font-family);
  font-weight: var(--input-placeholder-font-weight);
  color: var(--black-90);
  font-style: var(--input-placeholder-font-style);
  outline: none;
  border: none;
  background: transparent;
}

.element-customers .text-wrapper-6 {
  position: relative;
  align-self: stretch;
  font-family: var(--input-placeholder-font-family);
  font-weight: var(--input-placeholder-font-weight);
  color: var(--black-90);
  font-size: var(--input-placeholder-font-size);
  letter-spacing: var(--input-placeholder-letter-spacing);
  line-height: var(--input-placeholder-line-height);
  font-style: var(--input-placeholder-font-style);
  outline: none;
  border: none;
  background: transparent;
}

.element-customers .img {
  position: relative;
  width: 24px;
  height: 24px;
}

.element-customers .zip-code {
  display: flex;
  flex-direction: column;
  width: 700px;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 124px;
  left: 0;
}

.element-customers .input-content-3 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  align-self: stretch;
  width: 100%;
  display: flex;
  gap: 8px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.element-customers .city {
  position: absolute;
  width: 700px;
  height: 52px;
  top: 62px;
  left: 0;
}

.element-customers .input {
  flex-direction: column;
  width: 700px;
  align-items: flex-start;
  gap: 8px;
  display: flex;
  position: relative;
}

.element-customers .input-wrapper {
  display: flex;
  flex-direction: column;
  width: 700px;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-customers .top-wrapper {
  display: flex;
  flex-direction: column;
  width: 699px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.element-customers .text-wrapper-3 {
  position: absolute;
  top: 0;
  left: 0;
  font-family: var(--paragraph-1-medium-font-family);
  font-weight: var(--paragraph-1-medium-font-weight);
  color: var(--black-30);
  font-size: var(--paragraph-1-medium-font-size);
  letter-spacing: var(--paragraph-1-medium-letter-spacing);
  line-height: var(--paragraph-1-medium-line-height);
  white-space: nowrap;
  font-style: var(--paragraph-1-medium-font-style);
}

.element-customers .checkbox-primary {
  position: absolute;
  width: 86px;
  height: 24px;
  top: 0;
  left: 540px;
}

.element-customers .control {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
}

.element-customers .checkbox-radio {
  height: 24px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--black-1);
}

.element-customers .fi-check {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 4px;
  left: 4px;
}

.element-customers .checkbox-radio-2 {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: 2px;
  left: 35px;
}

.element-customers .text-wrapper-4 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-5);
  font-size: 14px;
  text-align: right;
  letter-spacing: 0;
  line-height: normal;
}

.element-customers .checkbox-hq {
  position: absolute;
  width: 56px;
  height: 24px;
  top: 0;
  left: 646px;
}

.element-customers .div-2 {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  border-radius: 8px;
  border: 1px solid;
  border-color: var(--black-1);
}

.element-customers .checkbox-radio-3 {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: 4px;
  left: 34px;
}

.element-customers .text-wrapper-5 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-3);
  font-size: 14px;
  text-align: right;
  letter-spacing: 0;
  line-height: normal;
}

.element-customers .contact-information {
  position: absolute;
  width: 703px;
  height: 210px;
  top: 201px;
  left: 150px;
}

.element-customers .inputs-2 {
  width: 701px;
  height: 176px;
  position: absolute;
  top: 34px;
  left: 0;
}

.element-customers .phone-no {
  position: absolute;
  width: 701px;
  height: 52px;
  top: 124px;
  left: 0;
}

.element-customers .frame {
  display: flex;
  flex-direction: column;
  width: 651px;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-customers .phone {
  display: flex;
  height: 52px;
  align-items: flex-start;
  gap: 6px;
  position: relative;
  align-self: stretch;
  width: 100%;
}

.element-customers .country-code {
  display: flex;
  flex-direction: column;
  width: 97px;
  align-items: flex-start;
  gap: 10px;
  padding: 8px 16px;
  position: relative;
  align-self: stretch;
  background-color: var(--inputdefaultbackground);
  border-radius: 8px;
}

.element-customers .input-content-4 {
  align-items: center;
  align-self: stretch;
  width: 100%;
  display: flex;
  gap: 8px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.element-customers .input-content-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 1;
  flex-grow: 1;
  margin-top: -3px;
  margin-bottom: -3px;
}

.element-customers .input-content-5 {
  flex-direction: column;
  height: 42px;
  align-items: flex-start;
  justify-content: space-around;
  display: flex;
  gap: 8px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.element-customers .iconly-light-arrow {
  position: relative;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
}

.element-customers .input-2 {
  flex-direction: column;
  width: 548px;
  align-items: flex-start;
  gap: 10px;
  padding: 8px 16px;
  align-self: stretch;
  background-color: var(--inputdefaultbackground);
  border-radius: 8px;
  display: flex;
  position: relative;
}

.element-customers .frame-2 {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 8px;
  left: 666px;
}

.element-customers .contact-email {
  display: flex;
  flex-direction: column;
  width: 700px;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 62px;
  left: 0;
}

.element-customers .add-company-info {
  position: absolute;
  width: 702px;
  height: 86px;
  top: 90px;
  left: 149px;
}

.element-customers .inputs-3 {
  width: 702px;
  height: 52px;
  position: absolute;
  top: 34px;
  left: 0;
}

.element-customers .name {
  display: flex;
  flex-direction: column;
  width: 343px;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-customers .input-3 {
  flex-direction: column;
  width: 343px;
  align-items: flex-start;
  gap: 8px;
  flex: 0 0 auto;
  display: flex;
  position: relative;
}

.element-customers .top-2 {
  width: 343px;
  height: 52px;
  padding: 8px 16px;
  position: relative;
  background-color: var(--inputdefaultbackground);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.element-customers .no-of-employees {
  display: flex;
  flex-direction: column;
  width: 343px;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 0;
  left: 359px;
}

.element-customers .input-4 {
  flex-direction: column;
  width: 701px;
  align-items: flex-start;
  gap: 8px;
  flex: 0 0 auto;
  margin-right: -358px;
  display: flex;
  position: relative;
}

.element-customers .frame-3 {
  display: inline-flex;
  align-items: center;
  gap: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.element-customers .heading {
  display: inline-flex;
  align-items: center;
  gap: 361px;
  position: absolute;
  top: 38px;
  left: 149px;
}

.element-customers .text-wrapper-7 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--subheading-3-medium-font-family);
  font-weight: var(--subheading-3-medium-font-weight);
  color: #000000;
  font-size: var(--subheading-3-medium-font-size);
  text-align: center;
  letter-spacing: var(--subheading-3-medium-letter-spacing);
  line-height: var(--subheading-3-medium-line-height);
  font-style: var(--subheading-3-medium-font-style);
}

.element-customers .table {
  position: absolute;
  width: 266px;
  height: 848px;
  top: 155px;
  left: 127px;
  background-color: #ffffff;
  border-radius: 12px;
}

.element-customers .company-names {
  position: absolute;
  width: 219px;
  height: 744px;
  top: 65px;
  left: 21px;
}

.element-customers .group {
  top: 720px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .text-wrapper-8 {
  position: absolute;
  top: 4px;
  left: 185px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.element-customers .text-wrapper-9 {
  top: 6px;
  position: absolute;
  left: 46px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.element-customers .group-2 {
  top: 672px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .group-3 {
  top: 624px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .group-4 {
  top: 576px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .group-5 {
  top: 528px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .group-6 {
  top: 480px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .group-7 {
  top: 432px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .text-wrapper-10 {
  position: absolute;
  top: 1px;
  left: 185px;
  color: var(--black-40);
  font-size: 14px;
  text-decoration: underline;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.element-customers .text-wrapper-11 {
  top: 2px;
  position: absolute;
  left: 46px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.element-customers .group-8 {
  top: 384px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .group-9 {
  top: 336px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .group-10 {
  top: 288px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .group-11 {
  top: 240px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .group-12 {
  top: 192px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .group-13 {
  top: 144px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .group-14 {
  top: 96px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .group-15 {
  top: 48px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .group-16 {
  top: 0;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.element-customers .text-wrapper-12 {
  position: absolute;
  top: 0;
  left: 185px;
  color: var(--black-40);
  font-size: 14px;
  text-decoration: underline;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.element-customers .company-name-heading {
  display: inline-flex;
  align-items: flex-end;
  gap: 8px;
  position: absolute;
  top: 24px;
  left: 17px;
}

.element-customers .text-wrapper-13 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-90);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.element-customers .img-2 {
  position: relative;
  width: 16px;
  height: 16px;
}

.element-customers .frame-wrapper {
  position: absolute;
  width: 1314px;
  height: 71px;
  top: 67px;
  left: 109px;
  background-color: #ffffff;
  border-radius: 12px;
}

.element-customers .frame-4 {
  display: inline-flex;
  align-items: center;
  gap: 846px;
  position: relative;
  top: 22px;
  left: 21px;
}

.element-customers .frame-5 {
  display: inline-flex;
  align-items: flex-start;
  gap: 7px;
  position: relative;
  flex: 0 0 auto;
}

.element-customers .left {
  display: flex;
  flex-direction: column;
  width: 103px;
  height: 29px;
  align-items: flex-start;
  gap: 10px;
  padding: 8px;
  position: relative;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-1);
}

.element-customers .iconly-light-search {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: -3.5px;
  margin-bottom: -3.5px;
}

.element-customers .text-wrapper-14 {
  position: relative;
  align-self: stretch;
  margin-top: -2px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-2);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.element-customers .right {
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
}

.element-customers .table-buttons {
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: relative;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-50);
  all: unset;
  box-sizing: border-box;
}

.element-customers .label-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
  margin-top: -1.5px;
  margin-bottom: -1.5px;
}

.element-customers .text-wrapper-15 {
  position: relative;
  width: fit-content;
  font-family: var(--label-2-regular-font-family);
  font-weight: var(--label-2-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-2-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-2-regular-letter-spacing);
  line-height: var(--label-2-regular-line-height);
  white-space: nowrap;
  font-style: var(--label-2-regular-font-style);
}

.element-customers .label-container-wrapper {
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: relative;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-50);
}

.element-customers .side-bar {
  position: absolute;
  width: 88px;
  height: 1024px;
  top: 0;
  left: 0;
  background-color: #ffffff;
}

.element-customers .overlap {
  position: relative;
  height: 1024px;
}

.element-customers .menu-items {
  display: inline-flex;
  flex-direction: column;
  height: 464px;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 128px;
  left: 16px;
}

.element-customers .active {
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 12px;
  overflow: hidden;
}

.element-customers .img-3 {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 16px;
  left: 16px;
}

.element-customers .overlap-group-2 {
  position: relative;
  width: 40px;
  height: 39px;
  top: 1px;
  left: 16px;
}

.element-customers .img-4 {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 15px;
  left: 0;
}

.element-customers .frame-6 {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 16px;
  background-color: #424242;
  border-radius: 500px;
  overflow: hidden;
}

.element-customers .text-wrapper-16 {
  position: absolute;
  top: 3px;
  left: 8px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--w-1);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.element-customers .iconly-bulk-user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 20px;
  flex: 0 0 auto;
  background-color: #424242;
  border-radius: 12px;
  width: 56px;
  position: relative;
}

.element-customers .iconly-bulk-user {
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: -4px;
  margin-right: -4px;
}

.element-customers .text-wrapper-17 {
  position: absolute;
  top: 3px;
  left: 5px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--w-1);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.element-customers .bottom {
  position: absolute;
  width: 46px;
  height: 46px;
  top: 828px;
  left: 8px;
}

.element-customers .menu-items-2 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 128px;
  left: 16px;
}

.element-customers .logout {
  display: flex;
  flex-direction: column;
  width: 56px;
  align-items: flex-start;
  gap: 10px;
  padding: 11px 16px;
  position: absolute;
  top: 846px;
  left: 16px;
  background-color: #5e63661a;
  border-radius: 16px;
}

.element-customers .fi-headphones-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
}

.element-customers .img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 20px;
  flex: 0 0 auto;
  background-color: var(--w-2);
  border-radius: 12px;
  width: 56px;
  position: relative;
}

.element-customers .active-2 {
  height: 56px;
  width: 56px;
  position: relative;
}

.element-customers .top-nav {
  width: 1352px;
  padding: 14px 21px;
  position: absolute;
  top: 0;
  left: 88px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.element-customers .nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.element-customers .text-wrapper-18 {
  position: relative;
  width: fit-content;
  font-family: var(--subheading-3-medium-font-family);
  font-weight: var(--subheading-3-medium-font-weight);
  color: var(--black-60);
  font-size: var(--subheading-3-medium-font-size);
  letter-spacing: var(--subheading-3-medium-letter-spacing);
  line-height: var(--subheading-3-medium-line-height);
  font-style: var(--subheading-3-medium-font-style);
}

.element-customers .profile {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.element-customers .iconly-bulk {
  position: relative;
  width: 20px;
  height: 20px;
}

.element-customers .profile-2 {
  position: relative;
  width: 32px;
  height: 32px;
  background-color: var(--w-1);
  border-radius: 8px;
}
