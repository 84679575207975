.credit-note {
  background-color: #f4f5f9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.credit-note input[type="date"]::-webkit-calendar-picker-indicator {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M2.06177 6.7695H13.9444" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.9614 9.37313H10.9676" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.00307 9.37313H8.00925" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.03859 9.37313H5.04477" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.9614 11.9641H10.9676" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.00307 11.9641H8.00925" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.03859 11.9641H5.04477" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.6958 1.83333V4.02719" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.31033 1.83333V4.02719" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M10.8255 2.88613H5.18064C3.22285 2.88613 2 3.97675 2 5.98148V12.0146C2 14.0508 3.22285 15.1667 5.18064 15.1667H10.8193C12.7833 15.1667 14 14.0697 14 12.065V5.98148C14.0062 3.97675 12.7895 2.88613 10.8255 2.88613Z" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    no-repeat;
    cursor: pointer;
}
.credit-note .text-wrappers{
  position: relative;
width: 125px;
  font-family: var(--input-placeholder-font-family);
  font-weight: var(--input-placeholder-font-weight);
  color: var(--black-90);
  font-style: var(--input-placeholder-font-style);
  outline: none;
  border: none;
  background: transparent;
}

.credit-note input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  flex-direction: row-reverse;
  border: none;
  gap: 15px;
cursor: pointer;
  /* Optional: Add padding or other styles as needed */
}



/* Style the select dropdown container */
.credit-note .select-container {
  position: relative;
  display: inline-block;
  /* border: 1px solid #BEC0CA;  */
  /* border-radius: 4px; Add rounded corners */
  padding: 5px;
}

/* Styles for the dropdown */
.credit-note .dropdown-1 {
  position: absolute;
  top: 100%;
  /* left: 240px; */
  display: none;
  width: 191px;
  background-color: white;
  border: 1px solid #bec0ca;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.credit-note .dropdown-1 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.credit-note .dropdown-1 li {
  padding: 8px 12px;
  cursor: pointer;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
  display: flex;
}

/* Show the dropdown when the Bulk icon is clicked */
.credit-note .label-container-wrapper:hover .dropdown-1 {
  display: block;
}

.credit-note .dropdown-1 input[type="checkbox"] {
  margin-right: 8px;
  vertical-align: middle;
}

.credit-note li:hover {
  background-color: rgba(190, 192, 202, 1);
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  /* color: var(--black-1); */
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}


.credit-note .div {
  background-color: var(--main-background);
  width: 1440px;
  height: 1024px;
  position: relative;
}

.credit-note .main-container {
  position: absolute;
  width: 1352px;
  height: 1024px;
  top: 0;
  left: 88px;
}

.credit-note .top-nav {
  display: flex;
  flex-direction: column;
  width: 1352px;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
}

.credit-note .nav-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 14px 21px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff;
}

.credit-note .nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.credit-note .text-wrapper {
  font-family: var(--subheading-3-medium-font-family);
  font-weight: var(--subheading-3-medium-font-weight);
  color: var(--black-60);
  font-size: var(--subheading-3-medium-font-size);
  position: relative;
  width: fit-content;
  letter-spacing: var(--subheading-3-medium-letter-spacing);
  line-height: var(--subheading-3-medium-line-height);
  font-style: var(--subheading-3-medium-font-style);
}

.credit-note .profile {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.credit-note .iconly-bulk {
  position: relative;
  width: 20px;
  height: 20px;
}

.credit-note .profile-2 {
  position: relative;
  width: 32px;
  height: 32px;
  background-color: var(--w-1);
  border-radius: 8px;
}

.credit-note .breadcrumbs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 20px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: var(--stroke);
}

.credit-note .div-2 {
  display: inline-flex;
  align-items: flex-start;
  gap: 11px;
  position: relative;
  flex: 0 0 auto;
}

.credit-note .img {
  position: relative;
  width: 16px;
  height: 16px;
}

.credit-note .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  color: var(--black-30);
  font-size: var(--label-1-regular-font-size);
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.credit-note .item {
  display: inline-flex;
  align-items: flex-start;
  gap: 11px;
  position: relative;
  flex: 0 0 auto;
  opacity: 0;
}

.credit-note .container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  position: absolute;
  top: 107px;
  left: 21px;
}

.credit-note .header {
  display: inline-flex;
  align-items: center;
  gap: 846px;
  position: relative;
  flex: 0 0 auto;
  background-color: transparent;
}

.credit-note .buttons {
  display: inline-flex;
  height: 36px;
  align-items: center;
  gap: 10px;
  padding: 17px 16px;
  position: relative;
  flex: 0 0 auto;
  background-color: #267dff;
  border-radius: 12px;
}



/* Hide the default checkbox */
.credit-note .custom-checkbox2 input[type="checkbox"] {
  display: none;
}

/* Create a custom checkbox style */
.credit-note .custom-checkbox2 .checkmark2 {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid var(--black-1);
  background-color: white;
  border-radius: 30%;
  margin-right: 10px;
  top: -1px;
  cursor: pointer;
  left : -1px;

}

/* Style the checkbox when it's checked */
.credit-note .custom-checkbox2 input[type="checkbox"]:checked + .checkmark2 {
  background-color: #007bff; /* Background color for checked state */
  border-color: #007bff; /* Border color for checked state */
}

/* Create the checkmark */
.credit-note .custom-checkbox2 .checkmark2::after {
  content: "☑️"; /* Unicode checkmark character */
  display: none; /* Initially hidden */
  position: absolute;
  top: 50%;
  left: 53%;
  mix-blend-mode: lighten;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255); /* Color of the checkmark */
  font-size: 20px; /* Adjust the size of the checkmark */
}
/* Show the checkmark when the checkbox is checked */
.credit-note .custom-checkbox2 input[type="checkbox"]:checked + .checkmark2::after {
  display: block; /* Show the checkmark when checked */
}





.credit-note .label-container {
  display: flex;
 gap: 20px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: -11px;
  margin-bottom: -11px;
}

.credit-note .credit-note .fi-plus {
  position: relative;
  width: 24px;
  height: 24px;
}

.credit-note .add-a-new-credit {
  position: relative;
  width: fit-content;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.credit-note .span {
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
}

.credit-note .table {
  position: relative;
  width: 1311px;
  height: 650px;
  background-color: #ffffff;
  border-radius: 12px;
}

.credit-note .overlap {
  position: relative;
  width: 1309px;
  height: 621px;
  top: 22px;
  left: 21px;
}

.credit-note .main-container-2 {
  position: absolute;
  width: 1262px;
  height: 621px;
  top: 0;
  left: 0;
}

.credit-note .frame {
  display: inline-flex;
  align-items: center;
  gap: 846px;
  position: absolute;
  top: 0;
  left: 0;
}

.credit-note .frame-2 {
  display: inline-flex;
  align-items: flex-start;
  gap: 7px;
  position: relative;
  flex: 0 0 auto;
}

.credit-note .left {
  display: flex;
  flex-direction: column;
  width: 176px;
  height: 29px;
  align-items: flex-start;
  gap: 10px;
  padding: 8px;
  position: relative;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-1);
}

.credit-note .input-content {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
}

.credit-note .iconly-light-search {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: -3.5px;
  margin-bottom: -3.5px;
}

.credit-note .div-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 8px;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
}

.credit-note .text-wrapper-3 {
  position: relative;
  align-self: stretch;
  margin-top: -2px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-2);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.credit-note .right {
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
}

.credit-note .table-buttons {
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: relative;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-50);
  all: unset;
  box-sizing: border-box;
}

.credit-note .label-container-2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
  margin-bottom: -1.5px;
  border: 1px solid var(--black-1);
  border-radius: 5px;
  padding: 8px 10px;
}

.credit-note .label-container-3 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 29px;

  position: relative;
  flex: 0 0 auto;
  margin-bottom: -1.5px;
  border: 1px solid var(--black-1);
  border-radius: 5px;
  padding: 8px 10px;
}



.credit-note .text-wrapper-4 {
  position: relative;
  width: fit-content;
  font-family: var(--label-2-regular-font-family);
  font-weight: var(--label-2-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-2-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-2-regular-letter-spacing);
  line-height: var(--label-2-regular-line-height);
  white-space: nowrap;
  font-style: var(--label-2-regular-font-style);
}

.credit-note .label-container-wrapper {
  display: inline-flex;
  height: 29px;
 
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: relative;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-1);
}

.credit-note .table-2 {
  position: absolute;
  width: 1262px;
  height: 572px;
  top: 49px;
  left: 0;
}

.credit-note .table-header {
  position: absolute;
  width: 1262px;
  height: 52px;
  top: 0;
  left: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--grey);
}

.credit-note .group {
  position: relative;
  width: 24px;
  height: 24px;
  top: 14px;
  border-radius: 8px;
  border: 1px solid;
  border-color: #cfd2d4;
}

.credit-note .table-row-container {
  height: 480px;
  top: 52px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.credit-note .table-row {
  position: absolute;
  width: 1262px;
  height: 48px;
  top: 0;
  left: 0;
}

.credit-note .col-contents {
  position: relative;
  width: 24px;
  height: 24px;
  top: 12px;
  border-radius: 8px;
  border: 1px solid;
  border-color: #cfd2d4;
}

.credit-note .col-contents-wrapper {
  height: 48px;
  top: 48px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.credit-note .table-row-2 {
  height: 48px;
  top: 96px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.credit-note .table-row-3 {
  height: 48px;
  top: 144px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.credit-note .table-row-4 {
  height: 48px;
  top: 192px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.credit-note .table-row-5 {
  height: 48px;
  top: 240px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.credit-note .table-row-6 {
  height: 48px;
  top: 288px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.credit-note .table-row-7 {
  height: 48px;
  top: 336px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.credit-note .table-row-8 {
  height: 48px;
  top: 384px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.credit-note .table-row-9 {
  height: 48px;
  top: 432px;
  position: absolute;
  width: 1262px;
  left: 0;
}

.credit-note .table-header-2 {
  position: absolute;
  width: 1262px;
  height: 40px;
  top: 532px;
  left: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: var(--grey);
}

.credit-note .text-wrapper-5 {
  position: absolute;
  top: 10px;
  left: 70px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-20);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-6 {
  position: absolute;
  top: 11px;
  left: 192px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--one-liquiditytextblack-50);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .frame-3 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: absolute;
  top: 9px;
  left: 1068px;
}

.credit-note .icon {
  display: inline-flex;
  flex-direction: column;
  height: 23px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 11px;
  position: relative;
  flex: 0 0 auto;
  background-color: #5e636614;
  border-radius: 8px;
}

.credit-note .frame-4 {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.credit-note .text-wrapper-7 {
  position: relative;
  width: fit-content;
  margin-top: -0.5px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-30);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.credit-note .text-wrapper-8 {
  position: relative;
  width: fit-content;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--one-liquiditytextblack-50);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .frame-5 {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 13px;
  left: 1222px;
}

.credit-note .frame-wrapper {
  display: inline-flex;
  flex-direction: column;
  height: 23px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 11px;
  position: absolute;
  top: 9px;
  left: 0;
  background-color: #5e636614;
  border-radius: 8px;
}

.credit-note .frame-6 {
  position: absolute;
  width: 1263px;
  height: 499px;
  top: 66px;
  left: 46px;
}

.credit-note .customer {
  position: absolute;
  width: 133px;
  height: 499px;
  top: 0;
  left: 0;
}

.credit-note .frame-7 {
  display: flex;
  width: 133px;
  align-items: flex-end;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.credit-note .text-wrapper-9 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-90);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .customers {
  position: absolute;
  width: 141px;
  height: 449px;
  top: 50px;
  left: 0;
}

.credit-note .text-wrapper-10 {
  position: absolute;
  width: 68px;
  top: 0;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-11 {
  width: 121px;
  top: 48px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-12 {
  position: absolute;
  width: 67px;
  top: 96px;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-13 {
  width: 108px;
  top: 144px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-14 {
  width: 107px;
  top: 192px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-15 {
  width: 86px;
  top: 240px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-16 {
  width: 76px;
  top: 288px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-17 {
  width: 76px;
  top: 336px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-18 {
  width: 96px;
  top: 384px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-19 {
  width: 109px;
  top: 432px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .phone {
  position: absolute;
  width: 147px;
  height: 499px;
  top: 0;
  left: 233px;
}

.credit-note .frame-8 {
  display: flex;
  width: 121px;
  align-items: flex-end;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.credit-note .text-wrapper-20 {
  width: 127px;
  top: 50px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-21 {
  width: 45px;
  top: 98px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-22 {
  width: 36px;
  top: 146px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-23 {
  width: 44px;
  top: 194px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-24 {
  width: 27px;
  top: 242px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-25 {
  width: 35px;
  top: 290px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-26 {
  width: 45px;
  top: 338px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-27 {
  width: 36px;
  top: 386px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-28 {
  width: 34px;
  top: 434px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-29 {
  width: 44px;
  top: 482px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .phone-2 {
  position: absolute;
  width: 147px;
  height: 499px;
  top: 0;
  left: 460px;
}

.credit-note .frame-9 {
  display: flex;
  width: 101px;
  align-items: flex-end;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.credit-note .text-wrapper-30 {
  width: 61px;
  top: 98px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-31 {
  width: 62px;
  top: 146px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-32 {
  width: 62px;
  top: 194px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-33 {
  width: 61px;
  top: 242px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-34 {
  position: absolute;
  width: 56px;
  top: 290px;
  left: 0;
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-35 {
  width: 60px;
  top: 338px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-36 {
  width: 61px;
  top: 386px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-37 {
  width: 61px;
  top: 434px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-38 {
  width: 59px;
  top: 482px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .overlap-group-wrapper {
  position: absolute;
  width: 142px;
  height: 499px;
  top: 0;
  left: 688px;
}

.credit-note .overlap-group {
  position: relative;
  height: 499px;
}

.credit-note .company {
  position: absolute;
  width: 142px;
  height: 499px;
  top: 0;
  left: 0;
}

.credit-note .frame-10 {
  display: inline-flex;
  align-items: flex-end;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.credit-note .group-wrapper {
  position: absolute;
  width: 36px;
  height: 449px;
  top: 50px;
  left: 0;
}

.credit-note .group-2 {
  position: relative;
  width: 56px;
  height: 449px;
}

.credit-note .text-wrapper-39 {
  width: 36px;
  top: 0;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-40 {
  width: 34px;
  top: 48px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-41 {
  width: 36px;
  top: 96px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-42 {
  width: 34px;
  top: 144px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-43 {
  width: 34px;
  top: 192px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-44 {
  width: 36px;
  top: 240px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-45 {
  width: 33px;
  top: 288px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-46 {
  width: 35px;
  top: 336px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-47 {
  width: 34px;
  top: 384px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-48 {
  width: 34px;
  top: 432px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .more {
  position: absolute;
  width: 54px;
  height: 449px;
  top: 50px;
  left: 58px;
}

.credit-note .text-wrapper-49 {
  position: absolute;
  width: 34px;
  top: 0;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-50 {
  top: 95px;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-51 {
  top: 432px;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-52 {
  top: 143px;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-53 {
  top: 48px;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-54 {
  top: 334px;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-55 {
  top: 381px;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-56 {
  top: 286px;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-57 {
  top: 191px;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-58 {
  top: 238px;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .overlap-wrapper {
  position: absolute;
  width: 203px;
  height: 499px;
  top: 0;
  left: 930px;
}

.credit-note .company-2 {
  position: absolute;
  width: 203px;
  height: 499px;
  top: 0;
  left: 0;
}

.credit-note .group-3 {
  position: absolute;
  width: 44px;
  height: 449px;
  top: 50px;
  left: 0;
}

.credit-note .group-4 {
  position: relative;
  width: 64px;
  height: 449px;
}

.credit-note .text-wrapper-59 {
  width: 44px;
  top: 0;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-60 {
  position: absolute;
  width: 38px;
  top: 48px;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-61 {
  width: 38px;
  top: 96px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-62 {
  width: 40px;
  top: 144px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-63 {
  width: 39px;
  top: 192px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-64 {
  width: 26px;
  top: 240px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-65 {
  width: 35px;
  top: 288px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-66 {
  width: 36px;
  top: 336px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-67 {
  width: 36px;
  top: 384px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .text-wrapper-68 {
  width: 35px;
  top: 432px;
  position: absolute;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.credit-note .more-2 {
  position: absolute;
  width: 54px;
  height: 446px;
  top: 50px;
  left: 61px;
}

.credit-note .text-wrapper-69 {
  top: 0;
  white-space: nowrap;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-70 {
  top: 95px;
  white-space: nowrap;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-71 {
  top: 429px;
  white-space: nowrap;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-72 {
  top: 142px;
  white-space: nowrap;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-73 {
  top: 47px;
  white-space: nowrap;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-74 {
  top: 332px;
  white-space: nowrap;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-75 {
  top: 379px;
  white-space: nowrap;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-76 {
  top: 284px;
  white-space: nowrap;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-77 {
  top: 189px;
  white-space: nowrap;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .text-wrapper-78 {
  top: 237px;
  white-space: nowrap;
  position: absolute;
  width: 34px;
  left: 0;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.credit-note .side-bar {
  position: absolute;
  width: 88px;
  height: 1024px;
  top: 0;
  left: 0;
  background-color: #ffffff;
}

.credit-note .menu-items {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 128px;
  left: 16px;
}

.credit-note .active {
  height: 56px;
  overflow: hidden;
  position: relative;
  width: 56px;
  border-radius: 12px;
}

.credit-note .iconly-light {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 16px;
  left: 16px;
}

.credit-note .iconly-bulk-user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 20px;
  flex: 0 0 auto;
  position: relative;
  width: 56px;
  border-radius: 12px;
}

.credit-note .iconly-bulk-user {
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: -4px;
  margin-right: -4px;
}

.credit-note .active-2 {
  position: relative;
  width: 56px;
  height: 56px;
}

.credit-note .bottom {
  position: absolute;
  width: 46px;
  height: 46px;
  top: 941px;
  left: 21px;
}
