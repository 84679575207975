.add-credit-note {
  background-color: #f4f5f9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}


.add-credit-note .top-nav {
  display: flex;
  flex-direction: column;
  width: 1352px;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
}

.add-credit-note .nav-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 14px 21px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff;
}

.add-credit-note .nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.add-credit-note .text-wrapper {
  font-family: var(--subheading-3-medium-font-family);
  font-weight: var(--subheading-3-medium-font-weight);
  color: var(--black-60);
  font-size: var(--subheading-3-medium-font-size);
  position: relative;
  width: fit-content;
  letter-spacing: var(--subheading-3-medium-letter-spacing);
  line-height: var(--subheading-3-medium-line-height);
  font-style: var(--subheading-3-medium-font-style);
}

.add-credit-note .profile {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.add-credit-note .iconly-bulk {
  position: relative;
  width: 20px;
  height: 20px;
  
}

.add-credit-note .profile-2 {
  position: relative;
  width: 32px;
  height: 32px;
  background-color: var(--w-1);
  border-radius: 8px;
}



.add-credit-note .breadcrumbs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 20px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: var(--w-1);
}

.add-credit-note .div-2 {
  display: inline-flex;
  align-items: flex-start;
  gap: 11px;
  position: relative;
  flex: 0 0 auto;
}

.add-credit-note .img {
  position: relative;
  width: 16px;
  height: 16px;
}

.add-credit-note .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  color: var(--black-30);
  font-size: var(--label-1-regular-font-size);
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.add-credit-note .item {
  display: inline-flex;
  align-items: flex-start;
  gap: 11px;
  position: relative;
  flex: 0 0 auto;
  opacity: 0;
}

.add-credit-note .container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  position: absolute;
  top: 107px;
  left: 21px;
}

.add-credit-note .header {
  position: absolute;
  width: 1279px;
  height: 59px;
  top: 97px;
  left: 124px;
  background-color: #ffffff;
  border-radius: 12px;
}





input[type="date"]::-webkit-calendar-picker-indicator {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M2.06177 6.7695H13.9444" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.9614 9.37313H10.9676" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.00307 9.37313H8.00925" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.03859 9.37313H5.04477" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.9614 11.9641H10.9676" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.00307 11.9641H8.00925" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.03859 11.9641H5.04477" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.6958 1.83333V4.02719" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.31033 1.83333V4.02719" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M10.8255 2.88613H5.18064C3.22285 2.88613 2 3.97675 2 5.98148V12.0146C2 14.0508 3.22285 15.1667 5.18064 15.1667H10.8193C12.7833 15.1667 14 14.0697 14 12.065V5.98148C14.0062 3.97675 12.7895 2.88613 10.8255 2.88613Z" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    no-repeat;
  cursor: pointer;
}

input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  flex-direction: row-reverse;
  border: none;
  gap: 15px;
  cursor: pointer;

  /* Optional: Add padding or other styles as needed */
}

.add-credit-note .div-2 {
  display: inline-flex;
  align-items: flex-start;
  gap: 11px;
  position: relative;
  flex: 0 0 auto;
}

/* Style the select dropdown container */
.select-container {
  position: relative;
  display: inline-block;
  /* border: 1px solid #BEC0CA;  */
  /* border-radius: 4px; Add rounded corners */
  padding: 5px;
}

/* Styles for the dropdown */
.dropdown-1 {
  position: absolute;
  top: 100%;
  /* left: 240px; */
  display: none;
  width: 191px;
  background-color: white;
  border: 1px solid #bec0ca;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.dropdown-1 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.dropdown-1 li {
  padding: 8px 12px;
  cursor: pointer;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
  display: flex;
}

/* Show the dropdown when the Bulk icon is clicked */
.bulk-actions:hover .dropdown-1 {
  display: block;
}

.dropdown-1 input[type="checkbox"] {
  margin-right: 8px;
  vertical-align: middle;
}

li:hover {
  background-color: rgba(190, 192, 202, 1);
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  /* color: var(--black-1); */
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

/* Hide the default checkbox */
.custom-checkbox2 input[type="checkbox"] {
  display: none;
}

/* Create a custom checkbox style */
.custom-checkbox2 .checkmark2 {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid var(--black-1);
  background-color: white;
  border-radius: 30%;
  margin-right: 10px;
  top: -1px;
  cursor: pointer;
  left: -1px;
}

/* Style the checkbox when it's checked */
.custom-checkbox2 input[type="checkbox"]:checked + .checkmark2 {
  background-color: #007bff; /* Background color for checked state */
  border-color: #007bff; /* Border color for checked state */
}

/* Create the checkmark */
.custom-checkbox2 .checkmark2::after {
  content: "\2713"; /* Unicode checkmark character */
  display: none; /* Initially hidden */
  position: absolute;
  top: 50%;
  left: 53%;
  mix-blend-mode: lighten;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255); /* Color of the checkmark */
  font-size: 20px; /* Adjust the size of the checkmark */
}
/* Show the checkmark when the checkbox is checked */
.custom-checkbox2 input[type="checkbox"]:checked + .checkmark2::after {
  display: block; /* Show the checkmark when checked */
}

.bar {
  display: flex;
  gap: 9px;
  /* row-gap: 42px; */
  position: relative;
  justify-content: start;
  top: 15px;
  left: 18px;
}
.search {
  display: flex;
  gap: 20px;

  left: 9px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}

.filters {
  display: flex;
  gap: 20px;

  left: 105px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}
.filter-2 {
  display: flex;

  left: 190px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}
.icn {
  position: relative;
  width: 16px;
  height: 16px;
  top: 2px;
}
.label {
  width: fit-content;
  font-family: var(--label-2-regular-font-family);
  font-weight: var(--label-2-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-2-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-2-regular-letter-spacing);
  line-height: var(--label-2-regular-line-height);
  white-space: nowrap;
  font-style: var(--label-2-regular-font-style);
}
.bulk-actions {
  display: flex;
  gap: 20px;
  left: 274px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}

.add-credit-note .div {
  background-color: var(--main-background);
  width: 1440px;
  height: 1024px;
  position: relative;
}

.add-credit-note .top-nav {
  display: flex;
  flex-direction: column;
  width: 1352px;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 88px;
}

.add-credit-note .nav-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 14px 21px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff;
}

.add-credit-note .nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.add-credit-note .text-wrapper {
  position: relative;
  width: fit-content;
  font-family: var(--subheading-3-medium-font-family);
  font-weight: var(--subheading-3-medium-font-weight);
  color: var(--black-60);
  font-size: var(--subheading-3-medium-font-size);
  letter-spacing: var(--subheading-3-medium-letter-spacing);
  line-height: var(--subheading-3-medium-line-height);
  font-style: var(--subheading-3-medium-font-style);
}

.add-credit-note .profile {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.add-credit-note .iconly-bulk {
  position: relative;
  width: 20px;
  height: 20px;
}

.add-credit-note .profile-2 {
  position: relative;
  width: 32px;
  height: 32px;
  background-color: var(--w-1);
  border-radius: 8px;
}

.add-credit-note .side-bar {
  position: absolute;
  width: 88px;
  height: 1024px;
  top: 0;
  left: 0;
  background-color: #ffffff;
}

.add-credit-note .menu-items {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 128px;
  left: 16px;
}

.add-credit-note .active {
  height: 56px;
  overflow: hidden;
  position: relative;
  width: 56px;
  border-radius: 12px;
}

.add-credit-note .iconly-light {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 16px;
  left: 16px;
}

.add-credit-note .iconly-bulk-user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 20px;
  flex: 0 0 auto;
  position: relative;
  width: 56px;
  border-radius: 12px;
}

.add-credit-note .iconly-bulk-user {
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: -4px;
  margin-right: -4px;
}

.add-credit-note .img {
  position: relative;
  width: 56px;
  height: 56px;
}

.add-credit-note .bottom {
  position: absolute;
  width: 46px;
  height: 46px;
  top: 941px;
  left: 21px;
}

.add-credit-note .header {
  position: absolute;
  width: 1279px;
  height: 60px;
  top: 94px;
  left: 125px;
  background-color: #ffffff;
  border-radius: 12px;
}

.add-credit-note .search-filter-bulk {
  position: relative;
  width: 366px;
  height: 29px;
  top: 22px;
  left: 21px;
}

.add-credit-note .left {
  display: flex;
  flex-direction: column;
  width: 103px;
  height: 29px;
  align-items: flex-start;
  gap: 10px;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-1);
}

.add-credit-note .input-content {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
}

.add-credit-note .iconly-light-search {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: -3.5px;
  margin-bottom: -3.5px;
}

.add-credit-note .div-wrapper {
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 8px;
}

.add-credit-note .text-wrapper-2 {
  position: relative;
  align-self: stretch;
  margin-top: -2px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-2);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.add-credit-note .table-buttons {
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 113px;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-50);
  all: unset;
  box-sizing: border-box;
}

.add-credit-note .label-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
  margin-top: -1.5px;
  margin-bottom: -1.5px;
}

.add-credit-note .img-2 {
  position: relative;
  width: 16px;
  height: 16px;
}

.add-credit-note .text-wrapper-3 {
  position: relative;
  width: fit-content;
  font-family: var(--label-2-regular-font-family);
  font-weight: var(--label-2-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-2-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-2-regular-letter-spacing);
  line-height: var(--label-2-regular-line-height);
  white-space: nowrap;
  font-style: var(--label-2-regular-font-style);
}

.add-credit-note .label-container-wrapper {
  left: 190px;
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: absolute;
  top: 0;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-50);
  all: unset;
  box-sizing: border-box;
}

.add-credit-note .table-buttons-2 {
  left: 267px;
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: absolute;
  top: 0;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-50);
}

.add-credit-note .form {
  position: absolute;
  width: 993px;
  height: 675px;
  top: 166px;
  left: 411px;
  background-color: #ffffff;
  border-radius: 12px;
}

.add-credit-note .buttons {
  display: flex;
  width: 210px;
  align-items: flex-start;
  gap: 310px;
  position: absolute;
  top: 586px;
  left: 156px;
}



.add-credit-note .text-wrappers{
  position: relative;

width: 125px;
  font-family: var(--input-placeholder-font-family);
  font-weight: var(--input-placeholder-font-weight);
  color: var(--black-90);
  font-style: var(--input-placeholder-font-style);
  outline: none;
  border: none;
  background: transparent;
}

.add-credit-note .cancel-button {
  display: flex;
  width: 180px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 17px 16px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px;
  border: 2px solid;
  border-color: var(--w-2);
  all: unset;
  box-sizing: border-box;
}

.add-credit-note .text-wrapper-4 {
  position: relative;
  flex: 1;
  margin-top: -2px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  border: 1px solid black;
  padding: 15px;
  width: 181px;
  border-radius: 10px;
}

.add-credit-note .save-button {
  display: flex;
  width: 180px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 17px 16px;
  position: absolute;
  top: 0;
  left: 521px;
  background-color: #267cff;
  border-radius: 12px;
  all: unset;
  box-sizing: border-box;
}

.add-credit-note .text-wrapper-5 {
  position: relative;
  flex: 1;
  margin-top: -2px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--w-2);
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  border: 1px solid black;
  padding: 15px;
  width: 182px;
  border-radius: 10px;
  background-color: #267cff;
  color: #e0e2e8;
}

.add-credit-note .inputs {
  position: absolute;
  width: 701px;
  height: 380px;
  top: 128px;
  left: 147px;
}

.add-credit-note .payment-receipt {
  display: flex;
  flex-direction: column;
  width: 701px;
  align-items: flex-start;
  gap: 4px;
  position: absolute;
  top: 328px;
  left: 0;
}

.add-credit-note .frame {
  display: flex;
  flex-direction: column;
  width: 698px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.add-credit-note .top {
  display: flex;
  flex-direction: column;
  height: 52px;
  align-items: flex-start;
  gap: 10px;
  padding: 8px 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  background-color: var(--inputdefaultbackground);
  border-radius: 8px;
}

.add-credit-note .text-wrapper-6 {
  position: relative;
  align-self: stretch;
  font-family: var(--input-placeholder-font-family);
  font-weight: var(--input-placeholder-font-weight);
  color: var(--black-90);
  font-size: var(--input-placeholder-font-size);
  letter-spacing: var(--input-placeholder-letter-spacing);
  line-height: var(--input-placeholder-line-height);
  font-style: var(--input-placeholder-font-style);
  outline: none;
  border: none;
  background: transparent;
}

.add-credit-note .invoice-reference {
  display: flex;
  flex-direction: column;
  width: 701px;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 246px;
  left: 0;
}

.add-credit-note .fi-chevron-down {
  position: relative;
  width: 24px;
  height: 24px;
}

.add-credit-note .credit-date {
  height: 52px;
  top: 164px;
  position: absolute;
  width: 700px;
  left: 0;
}

.add-credit-note .input {
  display: flex;
  flex-direction: column;
  width: 700px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
}

.add-credit-note .input-content-2 {
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 8px;
}

.add-credit-note .credit-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  top: 82px;
  position: absolute;
  width: 700px;
  left: 0;
}

.add-credit-note .top-wrapper {
  display: flex;
  flex-direction: column;
  width: 699px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.add-credit-note .company-name {
  position: absolute;
  width: 700px;
  height: 52px;
  top: 0;
  left: 0;
}

.add-credit-note .input-2 {
  width: 699px;
  height: 52px;
}

.add-credit-note .top-2 {
  position: relative;
  height: 52px;
  background-color: var(--inputdefaultbackground);
  border-radius: 8px;
}

.add-credit-note .input-content-3 {
  width: 558px;
  height: 36px;
  position: absolute;
  top: 8px;
  left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 8px;
}

.add-credit-note .fi-chevron-down-2 {
  position: absolute;
  top: 14px;
  left: 657px;
  width: 24px;
  height: 24px;
}

.add-credit-note .p {
  position: absolute;
  top: 57px;
  left: 147px;
  font-family: var(--subheading-3-medium-font-family);
  font-weight: var(--subheading-3-medium-font-weight);
  color: #000000;
  font-size: var(--subheading-3-medium-font-size);
  text-align: center;
  letter-spacing: var(--subheading-3-medium-letter-spacing);
  line-height: var(--subheading-3-medium-line-height);
  font-style: var(--subheading-3-medium-font-style);
}

.add-credit-note .companies-list {
  position: absolute;
  width: 266px;
  height: 848px;
  top: 166px;
  left: 125px;
  background-color: #ffffff;
  border-radius: 12px;
}

.add-credit-note .company-names {
  position: absolute;
  width: 219px;
  height: 744px;
  top: 64px;
  left: 27px;
}

.add-credit-note .group {
  top: 0;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .text-wrapper-7 {
  top: 0;
  position: absolute;
  left: 185px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.add-credit-note .controls {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  border-radius: 8px;
  border: 1px solid;
  border-color: var(--black-1);
}

.add-credit-note .text-wrapper-8 {
  position: absolute;
  top: 2px;
  left: 46px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.add-credit-note .group-2 {
  top: 48px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .text-wrapper-9 {
  position: absolute;
  top: 1px;
  left: 185px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.add-credit-note .group-3 {
  top: 96px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .group-4 {
  top: 144px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .group-5 {
  top: 192px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .group-6 {
  top: 240px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .group-7 {
  top: 288px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .group-8 {
  top: 336px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .group-9 {
  top: 384px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .group-10 {
  top: 432px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .group-11 {
  top: 480px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .text-wrapper-10 {
  top: 4px;
  position: absolute;
  left: 185px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.add-credit-note .text-wrapper-11 {
  position: absolute;
  top: 6px;
  left: 46px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.add-credit-note .group-12 {
  top: 528px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .group-13 {
  top: 576px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .group-14 {
  top: 624px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .group-15 {
  top: 672px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .group-16 {
  top: 720px;
  position: absolute;
  width: 223px;
  height: 24px;
  left: 0;
}

.add-credit-note .company-name-header-wrapper {
  display: inline-flex;
  align-items: flex-end;
  gap: 8px;
  position: absolute;
  top: 23px;
  left: 23px;
}

.add-credit-note .company-name-header {
  position: relative;
  width: 132px;
  height: 17px;
  margin-right: -2px;
}

.add-credit-note .text-wrapper-12 {
  position: absolute;
  top: 0;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-90);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.add-credit-note .vuesax-outline-sort {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 1px;
  left: 114px;
}
