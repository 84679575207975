.permissions {
  background-color: #f4f5f9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

/* Styles for the dropdown */
.dropdown-1 {
  position: absolute;
  top: 100%;
  /* left: 240px; */
  display: none;
  width: 191px;
  background-color: white;
  border: 1px solid #bec0ca;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.dropdown-1 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.dropdown-1 li {
  padding: 8px 12px;
  cursor: pointer;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
  display: flex;
}

/* Show the dropdown when the Bulk icon is clicked */
.bulk-actions:hover .dropdown-1 {
  display: block;
}

.dropdown-1 input[type="checkbox"] {
  margin-right: 8px;
  vertical-align: middle;
}

li:hover {
  background-color: rgba(190, 192, 202, 1);
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  /* color: var(--black-1); */
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.bar {
  display: flex;
  gap: 6px;
  position: relative;
  justify-content: center;
  top: 12px;
}
.search {
  display: flex;
  gap: 5px;
  left: 9px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: start;
  height: 29px;
  width: 167px;
}

.filters {
  display: flex;
  gap: 6px;

  left: 105px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}
.filter-2 {
  display: flex;
  gap: 6px;
  left: 190px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}
.icn {
  position: relative;
  width: 16px;
  height: 16px;
  top: 2px;
}
.label {
  width: fit-content;
  font-family: var(--label-2-regular-font-family);
  font-weight: var(--label-2-regular-font-weight);
  color: #abafb1;
  font-size: var(--label-2-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-2-regular-letter-spacing);
  line-height: var(--label-2-regular-line-height);
  white-space: nowrap;
  font-style: var(--label-2-regular-font-style);
}
.bulk-actions {
  display: flex;
  gap: 6px;
  left: 274px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}

/* Hide the default checkbox */
.custom-checkbox2 input[type="checkbox"] {
  display: none;
}

/* Create a custom checkbox style */
.custom-checkbox2 .checkmark2 {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid var(--black-1);
  background-color: white;
  border-radius: 30%;
  margin-right: 10px;
  top: -1px;
  cursor: pointer;
  left: -1px;
}

/* Style the checkbox when it's checked */
.custom-checkbox2 input[type="checkbox"]:checked + .checkmark2 {
  background-color: #007bff; /* Background color for checked state */
  border-color: #007bff; /* Border color for checked state */
}

/* Create the checkmark */
.custom-checkbox2 .checkmark2::after {
  content: "☑️"; /* Unicode checkmark character */
  display: none; /* Initially hidden */
  position: absolute;
  top: 50%;
  left: 53%;
  mix-blend-mode: lighten;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255); /* Color of the checkmark */
  font-size: 20px; /* Adjust the size of the checkmark */
}
/* Show the checkmark when the checkbox is checked */
.custom-checkbox2 input[type="checkbox"]:checked + .checkmark2::after {
  display: block; /* Show the checkmark when checked */
}

.permissions .div {
  background-color: var(--main-background);
  width: 1440px;
  height: 1024px;
  position: relative;
}

.permissions .side-bar {
  position: absolute;
  width: 88px;
  height: 1024px;
  top: 0;
  left: 0;
  background-color: #ffffff;
}

.permissions .menu-items {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 128px;
  left: 16px;
}

.permissions .active {
  height: 56px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  width: 56px;
}

.permissions .iconly-light {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 16px;
  left: 16px;
}

.permissions .iconly-bulk-user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 20px;
  flex: 0 0 auto;
  border-radius: 12px;
  position: relative;
  width: 56px;
}

.permissions .iconly-bulk-user {
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: -4px;
  margin-right: -4px;
}

.permissions .img {
  height: 56px;
  position: relative;
  width: 56px;
}

.permissions .bottom {
  position: absolute;
  width: 46px;
  height: 46px;
  top: 941px;
  left: 21px;
}

.permissions .top-nav {
  display: flex;
  flex-direction: column;
  width: 1352px;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 88px;
}

.permissions .nav-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 14px 21px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff;
}

.permissions .nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.permissions .text-wrapper {
  position: relative;
  width: fit-content;
  font-family: var(--subheading-3-medium-font-family);
  font-weight: var(--subheading-3-medium-font-weight);
  color: var(--black-60);
  font-size: var(--subheading-3-medium-font-size);
  letter-spacing: var(--subheading-3-medium-letter-spacing);
  line-height: var(--subheading-3-medium-line-height);
  font-style: var(--subheading-3-medium-font-style);
}

.permissions .profile {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.permissions .iconly-bulk {
  position: relative;
  width: 20px;
  height: 20px;
}

.permissions .profile-2 {
  position: relative;
  width: 32px;
  height: 32px;
  background-color: var(--w-1);
  border-radius: 8px;
}

.permissions .breadcrumbs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 20px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: var(--stroke);
}

.permissions .div-2 {
  display: inline-flex;
  align-items: flex-start;
  gap: 11px;
  position: relative;
  flex: 0 0 auto;
}

.permissions .img-2 {
  position: relative;
  width: 16px;
  height: 16px;
}

.permissions .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-30);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.permissions .item {
  opacity: 0;
  display: inline-flex;
  align-items: flex-start;
  gap: 11px;
  position: relative;
  flex: 0 0 auto;
}

.permissions .table-left {
  position: absolute;
  width: 408px;
  height: 650px;
  top: 107px;
  left: 138px;
  background-color: #ffffff;
  border-radius: 12px;
}

.permissions .checkbox-companies {
  position: absolute;
  width: 355px;
  height: 456px;
  top: 135px;
  left: 21px;
}

.permissions .group {
  position: absolute;
  width: 357px;
  height: 24px;
  top: 0;
  left: 0;
}

.permissions .div-3 {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  border-radius: 8px;
  border: 1px solid;
  border-color: #cfd2d4;
}

.permissions .text-wrapper-3 {
  position: absolute;
  top: 3px;
  left: 46px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.permissions .edit {
  position: absolute;
  width: 19px;
  height: 19px;
  top: 2px;
  left: 336px;
}

.permissions .group-2 {
  position: absolute;
  width: 357px;
  height: 24px;
  top: 48px;
  left: 0;
}

.permissions .group-3 {
  position: absolute;
  width: 357px;
  height: 24px;
  top: 96px;
  left: 0;
}

.permissions .group-4 {
  position: absolute;
  width: 357px;
  height: 24px;
  top: 144px;
  left: 0;
}

.permissions .group-5 {
  position: absolute;
  width: 357px;
  height: 24px;
  top: 192px;
  left: 0;
}

.permissions .group-6 {
  position: absolute;
  width: 357px;
  height: 24px;
  top: 240px;
  left: 0;
}

.permissions .group-7 {
  position: absolute;
  width: 357px;
  height: 24px;
  top: 288px;
  left: 0;
}

.permissions .group-8 {
  position: absolute;
  width: 357px;
  height: 24px;
  top: 336px;
  left: 0;
}

.permissions .group-9 {
  position: absolute;
  width: 357px;
  height: 24px;
  top: 384px;
  left: 0;
}

.permissions .group-10 {
  position: absolute;
  width: 357px;
  height: 24px;
  top: 432px;
  left: 0;
}

.permissions .checkbox-users {
  position: absolute;
  width: 114px;
  height: 24px;
  top: 83px;
  left: 22px;
}

.permissions .users {
  display: inline-flex;
  align-items: flex-end;
  gap: 8px;
  position: absolute;
  top: 3px;
  left: 46px;
}
.text-wrappers{
  position: relative;
  
width: 125px;
  font-family: var(--input-placeholder-font-family);
  font-weight: var(--input-placeholder-font-weight);
  color: var(--black-90);
  font-style: var(--input-placeholder-font-style);
  outline: none;
  border: none;
  background: transparent;
}
.permissions .text-wrapper-4 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: black;
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.permissions .seach-filter-bulk {
  position: absolute;
  width: 361px;
  height: 29px;
  top: 22px;
  left: 21px;
}

.permissions .left {
  display: flex;
  flex-direction: column;
  width: 176px;
  height: 29px;
  align-items: flex-start;
  gap: 10px;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-1);
}

.permissions .input-content {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
}

.permissions .iconly-light-search {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: -3.5px;
  margin-bottom: -3.5px;
}

.permissions .div-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 8px;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
}

.permissions .text-wrapper-5 {
  position: relative;
  align-self: stretch;
  margin-top: -2px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-2);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.permissions .table-buttons {
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 183px;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-50);
  all: unset;
  box-sizing: border-box;
}

.permissions .label-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
  margin-top: -1.5px;
  margin-bottom: -1.5px;
}

.permissions .text-wrapper-6 {
  position: relative;
  width: fit-content;
  font-family: var(--label-2-regular-font-family);
  font-weight: var(--label-2-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-2-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-2-regular-letter-spacing);
  line-height: var(--label-2-regular-line-height);
  white-space: nowrap;
  font-style: var(--label-2-regular-font-style);
}

.permissions .label-container-wrapper {
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 262px;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-50);
}

.permissions .table {
  position: absolute;
  width: 818px;
  height: 895px;
  top: 107px;
  left: 596px;
  background-color: #ffffff;
  border-radius: 12px;
}

.permissions .buttons {
  display: flex;
  width: 210px;
  align-items: flex-start;
  gap: 310px;
  position: absolute;
  top: 820px;
  left: 40px;
}

.permissions .button {
  display: flex;
  width: 212px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 17px 16px;
  position: relative;
  margin-right: -2px;
  border-radius: 12px;
  border: 2px solid;
  border-color: var(--w-2);
  all: unset;
  box-sizing: border-box;
}

.permissions .text-wrapper-7 {
  position: relative;
  flex: 1;
  margin-top: -2px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--w-2);
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  border: 1px solid black;
  padding: 15px;
  width: 181px;
  border-radius: 10px;
}

.permissions .buttons-2 {
  display: flex;
  width: 212px;
  align-items: flex-start;
  gap: 10px;
  padding: 17px 0px;
  position: relative;
  margin-right: -524px;
  background-color: #267cff;
  border-radius: 12px;
  all: unset;
  box-sizing: border-box;
}

.permissions .text-wrapper-8 {
  position: relative;
  flex: 1;
  margin-top: -2px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: #267cff;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  border: 1px solid black;
  padding: 15px;
  width: 182px;
  border-radius: 10px;
  background-color: #267cff;
  color: #ffff;
}

.permissions .reports {
  position: absolute;
  width: 821px;
  height: 399px;
  top: 401px;
  left: 0;
}

.permissions .title-checkbox-and {
  position: absolute;
  width: 821px;
  height: 37px;
  top: 362px;
  left: 0;
}

.permissions .p {
  top: 3px;
  position: absolute;
  left: 402px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.permissions .checkbox-read {
  display: inline-flex;
  align-items: flex-start;
  gap: 35px;
  position: absolute;
  top: 0;
  left: 287px;
}

.permissions .group-11 {
  position: relative;
  width: 72px;
  height: 24px;
  margin-right: -2px;
}

.permissions .text-wrapper-9 {
  left: 36px;
  position: absolute;
  top: 3px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: #267cff;
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.permissions .frame {
  top: 4px;
  left: 40px;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  position: absolute;
}

.permissions .text-wrapper-10 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.permissions .line {
  top: 36px;
  position: absolute;
  width: 819px;
  height: 1px;
  left: 0;
  object-fit: cover;
}

.permissions .title-checkbox-and-2 {
  height: 37px;
  top: 310px;
  position: absolute;
  width: 821px;
  left: 0;
}

.permissions .text-wrapper-11 {
  position: absolute;
  top: 2px;
  left: 402px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.permissions .title-checkbox-and-3 {
  height: 37px;
  top: 258px;
  position: absolute;
  width: 821px;
  left: 0;
}

.permissions .text-wrapper-12 {
  top: 1px;
  position: absolute;
  left: 402px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.permissions .title-checkbox-and-4 {
  height: 39px;
  top: 204px;
  position: absolute;
  width: 821px;
  left: 0;
}

.permissions .line-2 {
  top: 38px;
  position: absolute;
  width: 819px;
  height: 1px;
  left: 0;
  object-fit: cover;
}

.permissions .title-checkbox-and-5 {
  height: 39px;
  top: 150px;
  position: absolute;
  width: 821px;
  left: 0;
}

.permissions .title-checkbox-and-6 {
  height: 39px;
  top: 96px;
  position: absolute;
  width: 821px;
  left: 0;
}

.permissions .text-wrapper-13 {
  position: absolute;
  top: 4px;
  left: 402px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.permissions .title-checkbox-and-7 {
  height: 44px;
  top: 35px;
  position: absolute;
  width: 821px;
  left: 0;
}

.permissions .group-wrapper {
  display: inline-flex;
  align-items: flex-start;
  gap: 35px;
  position: absolute;
  top: 5px;
  left: 287px;
}

.permissions .text-wrapper-14 {
  position: absolute;
  top: 9px;
  left: 40px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.permissions .customer-service-wrapper {
  top: 0;
  left: 402px;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  position: absolute;
}

.permissions .line-3 {
  position: absolute;
  width: 819px;
  height: 1px;
  top: 43px;
  left: 0;
  object-fit: cover;
}

.permissions .text-wrapper-15 {
  position: absolute;
  top: 0;
  left: 40px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-90);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.permissions .div-4 {
  position: absolute;
  width: 819px;
  height: 320px;
  top: 66px;
  left: 0;
}

.permissions .audit-log-title-and {
  position: absolute;
  width: 819px;
  height: 38px;
  top: 282px;
  left: 0;
}

.permissions .line-4 {
  top: 37px;
  position: absolute;
  width: 819px;
  height: 1px;
  left: 0;
  object-fit: cover;
}

.permissions .div-5 {
  position: absolute;
  width: 707px;
  height: 24px;
  top: 0;
  left: 40px;
}

.permissions .frame-2 {
  top: 4px;
  left: 0;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  position: absolute;
}

.permissions .read-create-update {
  position: absolute;
  width: 460px;
  height: 24px;
  top: 0;
  left: 247px;
}

.permissions .checkbox-read-2 {
  position: absolute;
  width: 82px;
  height: 24px;
  top: 0;
  left: 0;
}

.permissions .checkbox-create {
  position: absolute;
  width: 93px;
  height: 24px;
  top: 0;
  left: 115px;
}

.permissions .checkbox-update {
  position: absolute;
  width: 97px;
  height: 24px;
  top: 0;
  left: 241px;
}

.permissions .checkbox-delete {
  position: absolute;
  width: 91px;
  height: 24px;
  top: 0;
  left: 371px;
}

.permissions .credit-note-title {
  position: absolute;
  width: 819px;
  height: 37px;
  top: 229px;
  left: 0;
}

.permissions .frame-3 {
  top: 3px;
  left: 0;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  position: absolute;
}

.permissions .controls {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  border-radius: 8px;
  border: 1px solid;
  border-color: var(--black-1);
}

.permissions .invoice-title-and {
  position: absolute;
  width: 819px;
  height: 36px;
  top: 176px;
  left: 0;
}

.permissions .line-5 {
  top: 35px;
  position: absolute;
  width: 819px;
  height: 1px;
  left: 0;
  object-fit: cover;
}

.permissions .frame-4 {
  top: 2px;
  left: 0;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  position: absolute;
}

.permissions .services-title-and {
  position: absolute;
  width: 819px;
  height: 35px;
  top: 123px;
  left: 0;
}

.permissions .line-6 {
  top: 34px;
  position: absolute;
  width: 819px;
  height: 1px;
  left: 0;
  object-fit: cover;
}

.permissions .frame-5 {
  top: 1px;
  left: 0;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  position: absolute;
}

.permissions .customers-title-and {
  position: absolute;
  width: 819px;
  height: 34px;
  top: 70px;
  left: 0;
}

.permissions .line-7 {
  position: absolute;
  width: 819px;
  height: 1px;
  top: 33px;
  left: 0;
  object-fit: cover;
}

.permissions .customer-title-and {
  position: absolute;
  width: 709px;
  height: 24px;
  top: 0;
  left: 40px;
}

.permissions .text-wrapper-16 {
  position: absolute;
  top: 0;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.permissions .permissions-heading {
  position: absolute;
  width: 767px;
  height: 52px;
  top: 0;
  left: 16px;
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--grey);
}

.permissions .text-wrapper-17 {
  position: absolute;
  top: 16px;
  left: 24px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-90);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.permissions .heading-title-and {
  position: absolute;
  width: 769px;
  height: 20px;
  top: 23px;
  left: 21px;
}

.permissions .text-wrapper-18 {
  position: absolute;
  top: 3px;
  left: 0;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.permissions .edit-vecter {
  position: absolute;
  width: 17px;
  height: 17px;
  top: 0;
  left: 750px;
}
