.customers {
  background-color: #f4f5f9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.customers input[type="date"]::-webkit-calendar-picker-indicator {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M2.06177 6.7695H13.9444" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.9614 9.37313H10.9676" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.00307 9.37313H8.00925" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.03859 9.37313H5.04477" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.9614 11.9641H10.9676" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.00307 11.9641H8.00925" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.03859 11.9641H5.04477" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.6958 1.83333V4.02719" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.31033 1.83333V4.02719" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M10.8255 2.88613H5.18064C3.22285 2.88613 2 3.97675 2 5.98148V12.0146C2 14.0508 3.22285 15.1667 5.18064 15.1667H10.8193C12.7833 15.1667 14 14.0697 14 12.065V5.98148C14.0062 3.97675 12.7895 2.88613 10.8255 2.88613Z" stroke="%2353545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    no-repeat;
  cursor: pointer;
}

.customers .text-wrappers {
  position: relative;

  width: 125px;
  font-family: var(--input-placeholder-font-family);
  font-weight: var(--input-placeholder-font-weight);
  color: var(--black-90);
  font-style: var(--input-placeholder-font-style);
  outline: none;
  border: none;
  background: transparent;
}

.customers input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  flex-direction: row-reverse;
  border: none;
  gap: 15px;
  cursor: pointer;

  /* Optional: Add padding or other styles as needed */
}

/* Hide the default checkbox */
.customers .custom-checkbox2 input[type="checkbox"] {
  display: none;
}

/* Create a custom checkbox style */
.customers .custom-checkbox2 .checkmark2 {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid var(--black-1);
  background-color: white;
  border-radius: 30%;
  margin-right: 10px;
  top: -1px;
  cursor: pointer;
  left: -1px;
}

/* Style the checkbox when it's checked */
.customers .custom-checkbox2 input[type="checkbox"]:checked + .checkmark2 {
  background-color: #007bff; /* Background color for checked state */
  border-color: #007bff; /* Border color for checked state */
}

.customers .bar {
  display: flex;
  gap: 9px;
  /* row-gap: 42px; */
  position: relative;
  justify-content: start;
  /* top: 20px; */
  left: 89px;
}
.customers .search {
  display: flex;
  gap: 5px;

  left: 9px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}

.customers .filters {
  display: flex;
  gap: 6px;

  left: 105px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}
.customers .filter-2 {
  display: flex;
  left: 190px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}
.customers .icn {
  position: relative;
  width: 16px;
  height: 16px;
  top: 2px;
}
.customers .label {
  width: fit-content;
  font-family: var(--label-2-regular-font-family);
  font-weight: var(--label-2-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-2-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-2-regular-letter-spacing);
  line-height: var(--label-2-regular-line-height);
  white-space: nowrap;
  font-style: var(--label-2-regular-font-style);
}
.customers .bulk-actions {
  display: flex;
  gap: 6px;
  left: 274px;
  border: 1px solid var(--black-1);
  top: 12px;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  flex: 0 0 auto;
  justify-content: center;
  height: 29px;
}

/* Create the checkmark */
.customers .custom-checkbox2 .checkmark2::after {
  content: "\2713"; /* Unicode checkmark character */
  display: none; /* Initially hidden */
  position: absolute;
  top: 50%;
  left: 53%;
  mix-blend-mode: lighten;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255); /* Color of the checkmark */
  font-size: 20px; /* Adjust the size of the checkmark */
}
/* Show the checkmark when the checkbox is checked */
.custom-checkbox2 input[type="checkbox"]:checked + .checkmark2::after {
  display: block; /* Show the checkmark when checked */
}

.customers .div {
  background-color: var(--main-background);
  width: 1440px;
  height: 1024px;
  position: relative;
}

.customers .top-nav {
  display: flex;
  flex-direction: column;
  width: 1352px;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 88px;
}

.customers .nav-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 14px 21px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff;
}

.customers .div-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.customers .text-wrapper {
  position: relative;
  width: fit-content;
  font-family: var(--subheading-3-medium-font-family);
  font-size: var(--subheading-3-medium-font-size);
  font-weight: var(--subheading-3-medium-font-weight);
  color: var(--black-60);
  letter-spacing: var(--subheading-3-medium-letter-spacing);
  line-height: var(--subheading-3-medium-line-height);
  font-style: var(--subheading-3-medium-font-style);
}

.customers .profile {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.customers .iconly-bulk {
  position: relative;
  width: 20px;
  height: 20px;
}

.customers .profile-2 {
  position: relative;
  width: 32px;
  height: 32px;
  background-color: var(--w-1);
  border-radius: 8px;
}

.customers .breadcrumbs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 20px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: var(--stroke);
}

.customers .div-3 {
  display: inline-flex;
  align-items: flex-start;
  gap: 11px;
  position: relative;
  flex: 0 0 auto;
}

.customers .img {
  position: relative;
  width: 16px;
  height: 16px;
}

.customers .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-30);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.customers .item {
  opacity: 0;
  display: inline-flex;
  align-items: flex-start;
  gap: 11px;
  position: relative;
  flex: 0 0 auto;
}

.customers .side-bar {
  position: absolute;
  width: 88px;
  height: 1024px;
  top: 0;
  left: 0;
  background-color: #ffffff;
}

.customers .overlap {
  position: relative;
  height: 1024px;
}

.customers .menu-items {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 128px;
  left: 16px;
}

.customers .active {
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 12px;
  overflow: hidden;
}

.customers .img-2 {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 16px;
  left: 16px;
}

.customers .overlap-group {
  position: relative;
  width: 40px;
  height: 39px;
  top: 1px;
  left: 16px;
}

.customers .img-3 {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 15px;
  left: 0;
}

.customers .frame {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 16px;
  background-color: #424242;
  border-radius: 500px;
  overflow: hidden;
}

.customers .text-wrapper-3 {
  position: absolute;
  top: 3px;
  left: 8px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--w-1);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.customers .iconly-bulk-user-wrapper {
  display: flex;
  flex-direction: column;
  width: 56px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 20px;
  position: relative;
  flex: 0 0 auto;
  background-color: #424242;
  border-radius: 12px;
}

.customers .iconly-bulk-user {
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: -4px;
  margin-right: -4px;
}

.customers .text-wrapper-4 {
  position: absolute;
  top: 3px;
  left: 5px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--w-1);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.customers .bottom {
  position: absolute;
  width: 46px;
  height: 46px;
  top: 941px;
  left: 21px;
}

.customers .logout {
  display: flex;
  flex-direction: column;
  width: 56px;
  align-items: flex-start;
  gap: 10px;
  padding: 11px 16px;
  position: absolute;
  top: 846px;
  left: 16px;
  background-color: #5e63661a;
  border-radius: 16px;
}

.customers .fi-headphones-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
}

.customers .img-4 {
  position: relative;
  width: 24px;
  height: 24px;
}

.customers .img-wrapper {
  display: flex;
  flex-direction: column;
  width: 56px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 20px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--w-2);
  border-radius: 12px;
}

.customers .active-2 {
  position: relative;
  width: 56px;
  height: 56px;
}

.customers .header {
  display: flex;
  width: 1311px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 105px;
  left: 109px;
  background-color: transparent;
}

.customers .text-wrapper-5 {
  position: relative;
  width: fit-content;
  font-family: var(--paragraph-1-medium-font-family);
  font-size: var(--paragraph-1-medium-font-size);
  white-space: nowrap;
  font-weight: var(--paragraph-1-medium-font-weight);
  color: var(--black-60);
  letter-spacing: var(--paragraph-1-medium-letter-spacing);
  line-height: var(--paragraph-1-medium-line-height);
  font-style: var(--paragraph-1-medium-font-style);
}

.customers .buttons {
  display: flex;
  width: 205px;
  height: 36px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 17px 16px;
  position: relative;
  background-color: #267dff;
  border-radius: 12px;
  cursor: pointer;
}

.customers .label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex: 1;
  flex-grow: 1;
  margin-top: -11px;
  margin-bottom: -11px;
}

.customers .text-wrapper-6 {
  position: relative;
  width: fit-content;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.customers .summary-row {
  display: flex;
  width: 1311px;
  align-items: flex-start;
  gap: 19px;
  position: absolute;
  top: 161px;
  left: 109px;
}

.customers .dashboard-summary {
  display: flex;
  flex-direction: column;
  height: 145px;
  align-items: flex-start;
  gap: 10px;
  padding: 11px 15px;
  position: relative;
  flex: 1;
  flex-grow: 1;
  background-color: #ffffff;
  border-radius: 12px;
}

.customers .summary-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
}

.customers .icon {
  position: relative;
  width: 36px;
  height: 36px;
  background-color: #424242;
  border-radius: 8px;
  overflow: hidden;
}

.customers .img-5 {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 8px;
  left: 8px;
}

.customers .filter {
  display: inline-flex;
  align-items: center;
  gap: 7px;
  position: relative;
  flex: 0 0 auto;
}

.customers .text-wrapper-7 {
  position: relative;
  width: fit-content;
  margin-top: -0.5px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-10);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.customers .frame-2 {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.customers .frame-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.customers .text-wrapper-8 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-30);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.customers .frame-4 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  position: relative;
  flex: 0 0 auto;
}

.customers .text-wrapper-9 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--subheading-3-medium-font-family);
  font-weight: var(--subheading-3-medium-font-weight);
  color: var(--black-60);
  font-size: var(--subheading-3-medium-font-size);
  letter-spacing: var(--subheading-3-medium-letter-spacing);
  line-height: var(--subheading-3-medium-line-height);
  font-style: var(--subheading-3-medium-font-style);
}

.customers .text-wrapper-10 {
  position: relative;
  width: fit-content;
  color: #424242;
  font-size: var(--label-1-regular-font-size);
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.customers .text-wrapper-11 {
  position: relative;
  width: fit-content;
  color: var(--w-12);
  font-size: var(--label-1-regular-font-size);
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.customers .text-wrapper-12 {
  position: relative;
  width: fit-content;
  opacity: 0;
  color: var(--actiongreen);
  font-size: var(--label-1-regular-font-size);
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.customers .table {
  position: absolute;
  width: 1311px;
  height: 650px;
  top: 326px;
  left: 109px;
  background-color: #ffffff;
  border-radius: 12px;
  overflow: scroll;
}

.customers .page-slider {
  position: absolute;
  width: 194px;
  height: 23px;
  top: 612px;
  left: 1089px;
}

.customers .frame-5 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.customers .frame-wrapper {
  display: inline-flex;
  flex-direction: column;
  height: 23px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 11px;
  position: relative;
  flex: 0 0 auto;
  background-color: #5e636614;
  border-radius: 8px;
}

.customers .frame-6 {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.customers .text-wrapper-13 {
  position: relative;
  width: fit-content;
  margin-top: -0.5px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-30);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.customers .text-wrapper-14 {
  position: relative;
  width: fit-content;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--one-liquiditytextblack-50);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.customers .frame-7 {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 4px;
  left: 154px;
}

.customers .items-per-page {
  position: absolute;
  width: 302px;
  height: 23px;
  top: 612px;
  left: 21px;
}

.customers .text-wrapper-15 {
  position: absolute;
  top: 2px;
  left: 70px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-20);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.customers .text-wrapper-16 {
  position: absolute;
  top: 3px;
  left: 192px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--one-liquiditytextblack-50);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.customers .div-wrapper {
  display: inline-flex;
  flex-direction: column;
  height: 23px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 11px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #5e636614;
  border-radius: 8px;
}

.customers .list-tile {
  display: table;
  width: 1224px;
  height: 24px;
  /* top: 0;
  left: 0; */
}
.table {
  display: table;
  vertical-align: top;
  width: 1224px;
  height: 34px;
}

.customers .company-name-no-of {
  /* position: relative; */
  display: table;
  justify-content: start;
  margin: 40px 0px 0px 0px;
  width: 1224px;
  height: 24px;
  table-layout: fixed;
  /* gap: 5rem; */

  /* top: 85px;
  left: 21px; */
}
.customers .table-cell {
  display: table-cell;
  width: 12rem;
  vertical-align: middle;
  padding-top: 16px;
  /* overflow:hidden; */
}

.customers .table-cell-phone {
  display: table-cell;
  width: 12rem;
  vertical-align: middle;
  padding-top: 16px;
  /* overflow:hidden; */
}
.customers .table-cell-status {
  display: table-cell;
  width: 6rem;
  vertical-align: middle;
  padding-top: 16px;
  /* overflow:hidden; */
}
.customers .table-cell-contact-name {
  display: table-cell;
  width: 9rem;
  vertical-align: middle;
  padding-top: 16px;
  /* overflow:hidden; */
}
.customers .table-cell-checkbox {
  display: table-cell;
  width: 4%;
  height: 24px;
  padding-top: 16px;
}
.customers .table-cell-checkbox2 {
  display: table-cell;
  width: 4%;
  height: 24px;
  padding-top: 16px;
}

.customers .div-4 {
  /* position: absolute; */
  display: flex;

  width: 24px;
  height: 24px;
  /* top: 0;
  left: 0; */
  border-radius: 8px;
  border: 1px solid;
  border-color: #cfd2d4;
}

.customers .frame-8 {
  display: flex;
  justify-content: start;
  /* width: 40px; */
  /* position: absolute;
  top: 3px;
  left: 46px; */
  /* border-style: solid;  
  border-width: 5px;  */
  padding: 0;
  margin: 0;
}

.customers .text-wrapper-17 {
  padding-right: 12px;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-90);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.customers .frame-9 {
  display: flex;
  justify-content: start;
  /* position: absolute;
  top: 3px;
  left: 245px; */
  /* border-style: solid;  
  border-width: 5px;  */
}

.customers .frame-10 {
  display: flex;
  justify-content: start;
  /* position: absolute;
  top: 3px;
  left: 454px; */
  /* border-style: solid;  
  border-width: 5px;  */
}

.customers .frame-11 {
  display: flex;
  justify-content: start;
  /* position: absolute;
  top: 3px;
  left: 643px; */
  /* border-style: solid;  
  border-width: 5px;  */
}

.customers .frame-12 {
  display: flex;
  justify-content: start;
  /* position: absolute;
  top: 3px;
  left: 909px; */
  /* border-style: solid;  
  border-width: 5px;  */
}

.customers .frame-13 {
  display: flex;
}

.customers .frame-14 {
  display: flex;
  justify-content: start;
  /* position: absolute;
  top: 3px;
  left: 1157px; */
  /* border-style: solid;  
  border-width: 5px;  */
}

.customers .checkboxs-company {
  /* position: absolute; */
  /* display: flex;
  flex-direction: column; */

  width: 1218px;
  height: 456px;
  /* top: 135px;
  left: 21px; */
}

.customers .group {
  /* position: absolute; */
  /* display: flex; */
  width: 1224px;
  height: 24px;
  /* top: 0;
  left: 0; */
}

.customers .text-wrapper-18 {
  /* position: absolute;
  top: 3px;
  left: 46px; */

  /* padding-left: 6px;
  padding-right: 6px; */
  text-align: start;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
  /* border-style: solid;  
  border-width: 5px;  */
}

.customers .text-wrapper-19 {
  /* position: absolute;
  top: 3px;
  left: 245px; */
  /* padding-left: 6px;
  padding-right: 6px; */
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.customers .text-wrapper-20 {
  /* top: 3px;
  left: 454px;
  position: absolute; */
  /* padding-left: 6px;
  padding-right: 6px; */
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.customers .group-2 {
  /* position: absolute; */
  /* padding-left: 6px;
  padding-right: 6px; */
  display: flex;
  width: auto;
  height: 18px;
  /* top: 2px;
  left: 643px; */
}

.customers .text-wrapper-21 {
  /* top: 0;
  left: 163px;
  position: absolute; */
  margin-left: 20px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.customers .text-wrapper-22 {
  /* position: absolute; */
  width: 126px;
  /* top: 1px;
  left: 0; */
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
  overflow: scroll;
}

.customers .u-copy-alt {
  /* position: absolute; */
  width: 16px;
  height: 16px;
  /* top: 2px;
  left: 136px; */
}

.customers .group-3 {
  /* position: absolute; */
  display: flex;
  justify-content: start;
  height: 18px;
  /* top: 2px;
  left: 911px; */
}

.customers .text-wrapper-23 {
  /* top: 0;
  left: 143px;
  position: absolute; */
  margin-left: 20px;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.customers .text-wrapper-24 {
  /* position: absolute;
  top: 1px;
  left: 0; */
  overflow: scroll;
  flex: 0.5;
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.customers .u-copy-alt-2 {
  /* top: 2px;
  left: 115px;
  position: absolute; */
  width: 16px;
  height: 16px;
}

.customers .status {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 11px;
  /* position: absolute;
  top: 1px;
  left: 1160px; */
  background-color: #424242;
  border-radius: 8px;
}

/*  ---------------------------------------
-----------------------------------------
  */

.dropdown {
  list-style: none;
  outline: none;
  background-color: transparent;
}
/* Reset default styles for select element */
select {
  /* appearance: none;  */
  /* /* -webkit-appearance: none;  */
  /* -moz-appearance: none; For Firefox */
  border: none; /* Remove border */
  outline: none; /* Remove outline */
  background: transparent; /* Set a transparent background */
  /* padding: 0; Remove padding */
  cursor: pointer; /* Change cursor to pointer */

  position: relative;
  width: fit-content;
  margin-top: -0.5px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-10);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}
.dropdown option[value="ThisMonth"],
.dropdown option[value="ThisQuarter"] {
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-10);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

/* Style the select dropdown container */
.select-container {
  position: relative;
  display: inline-block;
  /* border: 1px solid #BEC0CA;  */
  /* border-radius: 4px; Add rounded corners */
  padding: 5px;
}

/* Styles for the dropdown */
.dropdown-1 {
  position: absolute;
  top: 100%;
  /* left: 240px; */
  display: none;
  width: 191px;
  background-color: white;
  border: 1px solid #bec0ca;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.dropdown-1 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.dropdown-1 li {
  padding: 8px 12px;
  cursor: pointer;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
  display: flex;
}

/* Show the dropdown when the Bulk icon is clicked */
.bulk-actions:hover .dropdown-1 {
  display: block;
}

.dropdown-1 input[type="checkbox"] {
  margin-right: 8px;
  vertical-align: middle;
}

li:hover {
  background-color: rgba(190, 192, 202, 1);
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  /* color: var(--black-1); */
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.customers .text-wrapper-25 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--w-1);
  font-size: var(--label-1-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
  cursor: pointer;
}
/* ------------------------------------------
   ------------------------------------------ */

.customers .group-4 {
  position: absolute;
  width: 1224px;
  height: 24px;
  top: 48px;
  left: 0;
}

.customers .group-5 {
  position: absolute;
  width: 1224px;
  height: 24px;
  top: 96px;
  left: 0;
}

.customers .group-6 {
  position: absolute;
  width: 1224px;
  height: 24px;
  top: 144px;
  left: 0;
}

.customers .group-7 {
  position: absolute;
  width: 1224px;
  height: 24px;
  top: 192px;
  left: 0;
}

.customers .group-8 {
  position: absolute;
  width: 1224px;
  height: 24px;
  top: 240px;
  left: 0;
}

.customers .group-9 {
  position: absolute;
  width: 1224px;
  height: 24px;
  top: 288px;
  left: 0;
}

.customers .group-10 {
  position: absolute;
  width: 1224px;
  height: 24px;
  top: 336px;
  left: 0;
}

.customers .group-11 {
  position: absolute;
  width: 1224px;
  height: 24px;
  top: 384px;
  left: 0;
}

.customers .group-12 {
  position: absolute;
  width: 1224px;
  height: 24px;
  top: 432px;
  left: 0;
}

.customers .group-13 {
  position: absolute;
  width: 201px;
  height: 19px;
  top: 3px;
  left: 643px;
}

.customers .text-wrapper-26 {
  top: 2px;
  left: 163px;
  position: absolute;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--black-40);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.customers .text-wrapper-27 {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--black-40);
  font-size: var(--paragraph-2-regular-font-size);
  font-family: var(--paragraph-2-regular-font-family);
  font-weight: var(--paragraph-2-regular-font-weight);
  letter-spacing: var(--paragraph-2-regular-letter-spacing);
  line-height: var(--paragraph-2-regular-line-height);
  font-style: var(--paragraph-2-regular-font-style);
}

.customers .u-copy-alt-3 {
  top: 1px;
  left: 136px;
  position: absolute;
  width: 16px;
  height: 16px;
}

.customers .group-14 {
  position: absolute;
  width: 181px;
  height: 17px;
  top: 4px;
  left: 911px;
}

.customers .u-copy-alt-4 {
  top: 1px;
  left: 115px;
  position: absolute;
  width: 16px;
  height: 16px;
}

.customers .customers-title {
  position: absolute;
  width: 625px;
  height: 29px;
  top: 22px;
  left: 21px;
}

.customers .text-wrapper-28 {
  position: absolute;
  top: 5px;
  left: 0;
  font-family: var(--paragraph-1-medium-font-family);
  font-size: var(--paragraph-1-medium-font-size);
  white-space: nowrap;
  font-weight: var(--paragraph-1-medium-font-weight);
  color: var(--black-60);
  letter-spacing: var(--paragraph-1-medium-letter-spacing);
  line-height: var(--paragraph-1-medium-line-height);
  font-style: var(--paragraph-1-medium-font-style);
}

.customers .group-15 {
  position: absolute;
  width: 524px;
  height: 29px;
  top: 0;
  left: 99px;
}

.customers .left {
  display: flex;
  flex-direction: column;
  width: 176px;
  height: 29px;
  align-items: flex-start;
  gap: 10px;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-1);
}

.customers .input-content {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
}

.customers .iconly-light-search {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: -3.5px;
  margin-bottom: -3.5px;
}

.customers .input-content-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 8px;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
}

.customers .text-wrapper-29 {
  position: relative;
  align-self: stretch;
  margin-top: -2px;
  font-family: var(--label-1-regular-font-family);
  font-weight: var(--label-1-regular-font-weight);
  color: var(--black-2);
  font-size: var(--label-1-regular-font-size);
  letter-spacing: var(--label-1-regular-letter-spacing);
  line-height: var(--label-1-regular-line-height);
  font-style: var(--label-1-regular-font-style);
}

.customers .table-buttons {
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 187px;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-50);
  all: unset;
  box-sizing: border-box;
}

.customers .label-container-2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
  margin-top: -1.5px;
  margin-bottom: -1.5px;
}

.customers .text-wrapper-30 {
  position: relative;
  width: fit-content;
  font-family: var(--label-2-regular-font-family);
  font-weight: var(--label-2-regular-font-weight);
  color: var(--black-50);
  font-size: var(--label-2-regular-font-size);
  text-align: center;
  letter-spacing: var(--label-2-regular-letter-spacing);
  line-height: var(--label-2-regular-line-height);
  white-space: nowrap;
  font-style: var(--label-2-regular-font-style);
}

.customers .label-container-wrapper {
  left: 265px;
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: absolute;
  top: 0;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-50);
  all: unset;
  box-sizing: border-box;
}

.customers .button {
  left: 343px;
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: absolute;
  top: 0;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-50);
  all: unset;
  box-sizing: border-box;
}

.customers .table-buttons-2 {
  left: 425px;
  display: inline-flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
  position: absolute;
  top: 0;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--black-50);
}
