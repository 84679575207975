.dashboard-empty {
    background-color: #f4f5f9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  
  .dashboard-empty .dropdown {
    list-style: none;
    outline: none;
    background-color: transparent;
  }
  /* Reset default styles for select element */
  .dashboard-empty select {
    /* appearance: none;  */
    /* /* -webkit-appearance: none;  */
    /* -moz-appearance: none; For Firefox */
    border: none; /* Remove border */
    outline: none; /* Remove outline */
    background: transparent; /* Set a transparent background */
    /* padding: 0; Remove padding */
    cursor: pointer; /* Change cursor to pointer */
  
    position: relative;
    width: fit-content;
    margin-top: -0.5px;
    font-family: var(--label-1-regular-font-family);
    font-weight: var(--label-1-regular-font-weight);
    color: var(--black-10);
    font-size: var(--label-1-regular-font-size);
    letter-spacing: var(--label-1-regular-letter-spacing);
    line-height: var(--label-1-regular-line-height);
    font-style: var(--label-1-regular-font-style);
  }
  .dropdown option[value="ThisMonth"],
  .dropdown option[value="ThisQuarter"] {
    font-family: var(--label-1-regular-font-family);
    font-weight: var(--label-1-regular-font-weight);
    color: var(--black-10);
    font-size: var(--label-1-regular-font-size);
    letter-spacing: var(--label-1-regular-letter-spacing);
    line-height: var(--label-1-regular-line-height);
    font-style: var(--label-1-regular-font-style);
  }
  
  /* Style the select dropdown container */
  .select-container {
    position: relative;
    display: inline-block;
    /* border: 1px solid #BEC0CA;  */
    /* border-radius: 4px; Add rounded corners */
    padding: 5px;
  }
  
  
  .dashboard-empty .div {
    background-color: var(--main-background);
    width: 1440px;
    height: 1024px;
    position: relative;
  }
  
  .dashboard-empty .side-bar {
    position: absolute;
    width: 88px;
    height: 1024px;
    top: 0;
    left: 0;
    background-color: #ffffff;
  }
  
  .dashboard-empty .menu-items {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    position: absolute;
    top: 128px;
    left: 16px;
  }
  
  .dashboard-empty .active {
    display: flex;
    flex-direction: column;
    width: 56px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 50px;
    /* padding: 16px 20px; */
    fill: white;
    position: relative;
    flex: 0 0 auto;
    
    
    background-color: var(--w-2);
    border-radius: 12px;
  }

.dashboard-empty .active .iconly-bulk-user path{
  fill :#B1B1B1;
}

.dashboard-empty .active .iconly-light path{
  stroke :white;
}

.dashboard-empty .active .img path{
  stroke :white;
}



  
  .dashboard-empty .inner-container {
    display: inline-flex;
    align-items: center;
    gap: 16px;
    position: relative;
    flex: 0 0 auto;
    margin-left: -4px;
    margin-right: -4px;
  }
  
  .dashboard-empty .iconly-bulk-category {
    position: relative;
    width: 24px;
    height: 24px;
  }
  
  .dashboard-empty .category {
    position: relative;
    width: 20px;
    height: 20px;
    top: 2px;
    left: 2px;
  }
  
  .dashboard-empty .overlap-group {
    position: relative;
    height: 20px;
  }
  
  .dashboard-empty .fill {
    position: absolute;
    width: 8px;
    height: 9px;
    top: 0;
    left: 12px;
  }
  
  .dashboard-empty .combined-shape {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
  }
  
  .dashboard-empty .iconly-bulk-user-wrapper {
    display: flex;
    flex-direction: column;
    width: 56px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 16px 20px;
    position: relative;
    flex: 0 0 auto;
    border-radius: 12px;
  }
  
  .dashboard-empty .iconly-bulk-user {
    position: relative;
    width: 24px;
    height: 24px;
    margin-left: -4px;
    margin-right: -4px;
  }
  
  .dashboard-empty .img {
    position: relative;
    width: 56px;
    height: 56px;
  }
  
  .dashboard-empty .bottom {
    position: absolute;
    width: 46px;
    height: 46px;
    top: 941px;
    left: 21px;
  }
  
  .dashboard-empty .main-container {
    width: 1352px;
    height: 1024px;
    left: 88px;
    position: absolute;
    top: 0;
  }
  
  .dashboard-empty .top-nav {
    flex-direction: column;
    width: 1352px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
  }
  
  .dashboard-empty .nav-container-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 14px 21px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
    background-color: #ffffff;
  }
  
  .dashboard-empty .div-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }
  
  .dashboard-empty .text-wrapper {
    position: relative;
    width: fit-content;
    font-family: var(--subheading-3-medium-font-family);
    font-weight: var(--subheading-3-medium-font-weight);
    color: #44464d;
    font-size: var(--subheading-3-medium-font-size);
    letter-spacing: var(--subheading-3-medium-letter-spacing);
    line-height: var(--subheading-3-medium-line-height);
    font-style: var(--subheading-3-medium-font-style);
  }
  
  .dashboard-empty .profile {
    display: inline-flex;
    align-items: center;
    gap: 20px;
    position: relative;
    flex: 0 0 auto;
  }
  
  .dashboard-empty .iconly-bulk {
    position: relative;
    width: 20px;
    height: 20px;
  }
  
  .dashboard-empty .profile-2 {
    position: relative;
    width: 32px;
    height: 32px;
    background-color: var(--w-1);
    border-radius: 8px;
  }
  
  .dashboard-empty .breadcrumbs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 4px 20px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
    background-color: #ffffff;
    border-top-width: 1px;
    border-top-style: solid;
    border-color: var(--stroke);
  }
  
  .dashboard-empty .contnet {
    display: inline-flex;
    align-items: flex-start;
    gap: 11px;
    position: relative;
    flex: 0 0 auto;
  }
  
  .dashboard-empty .img-2 {
    position: relative;
    width: 16px;
    height: 16px;
  }
  
  .dashboard-empty .item {
    display: inline-flex;
    align-items: flex-start;
    gap: 11px;
    position: relative;
    flex: 0 0 auto;
    opacity: 0;
  }
  
  .dashboard-empty .text-wrapper-2 {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: var(--label-1-regular-font-family);
    font-weight: var(--label-1-regular-font-weight);
    color: var(--black-30);
    font-size: var(--label-1-regular-font-size);
    letter-spacing: var(--label-1-regular-letter-spacing);
    line-height: var(--label-1-regular-line-height);
    font-style: var(--label-1-regular-font-style);
  }
  
  .dashboard-empty .top-three {
    width: 1309px;
    gap: 19px;
    position: absolute;
    top: 92px;
    left: 12px;
    display: flex;
    align-items: flex-start;
  }
  
  .dashboard-empty .dashboard-summary {
    display: flex;
    flex-direction: column;
    height: 145px;
    align-items: flex-start;
    gap: 10px;
    padding: 11px 15px;
    position: relative;
    flex: 1;
    flex-grow: 1;
    background-color: #ffffff;
    border-radius: 12px;
  }
  
  .dashboard-empty .summary-container {
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-self: stretch;
    width: 100%;
  }
  
  .dashboard-empty .icon {
    position: relative;
    width: 36px;
    height: 36px;
  }
  
  .dashboard-empty .filter {
    display: inline-flex;
    align-items: center;
    gap: 7px;
    position: relative;
    flex: 0 0 auto;
  }
  
  .dashboard-empty .text-wrapper-3 {
    position: relative;
    width: fit-content;
    margin-top: -0.5px;
    font-family: var(--label-1-regular-font-family);
    font-weight: var(--label-1-regular-font-weight);
    color: var(--black-10);
    font-size: var(--label-1-regular-font-size);
    letter-spacing: var(--label-1-regular-letter-spacing);
    line-height: var(--label-1-regular-line-height);
    font-style: var(--label-1-regular-font-style);
  }
  
  .dashboard-empty .frame {
    display: flex;
    align-items: flex-start;
    gap: 32px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }
  
  .dashboard-empty .frame-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    position: relative;
    flex: 1;
    flex-grow: 1;
  }
  
  .dashboard-empty .text-wrapper-4 {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: "Inter", Helvetica;
    font-weight: 400;
    color: var(--black-30);
    font-size: 14px;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .frame-3 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    position: relative;
    flex: 0 0 auto;
  }
  
  .dashboard-empty .text-wrapper-5 {
    margin-top: -1px;
    font-family: var(--subheading-3-medium-font-family);
    font-weight: var(--subheading-3-medium-font-weight);
    color: #44464d;
    font-size: var(--subheading-3-medium-font-size);
    position: relative;
    width: fit-content;
    letter-spacing: var(--subheading-3-medium-letter-spacing);
    line-height: var(--subheading-3-medium-line-height);
    font-style: var(--subheading-3-medium-font-style);
  }
  
  .dashboard-empty .text-wrapper-6 {
    position: relative;
    width: fit-content;
    font-family: var(--label-1-regular-font-family);
    font-weight: var(--label-1-regular-font-weight);
    color: var(--w-1);
    font-size: var(--label-1-regular-font-size);
    letter-spacing: var(--label-1-regular-letter-spacing);
    line-height: var(--label-1-regular-line-height);
    font-style: var(--label-1-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-7 {
    opacity: 0;
    font-family: var(--label-1-regular-font-family);
    font-weight: var(--label-1-regular-font-weight);
    color: var(--actiongreen);
    font-size: var(--label-1-regular-font-size);
    position: relative;
    width: fit-content;
    letter-spacing: var(--label-1-regular-letter-spacing);
    line-height: var(--label-1-regular-line-height);
    font-style: var(--label-1-regular-font-style);
  }
  
  .dashboard-empty .overlap {
    position: absolute;
    width: 1309px;
    height: 337px;
    top: 247px;
    left: 12px;
  }
  
  .dashboard-empty .main-content {
    display: flex;
    width: 1309px;
    height: 337px;
    align-items: flex-start;
    gap: 18px;
    left: 0;
    position: absolute;
    top: 0;
  }
  
  .dashboard-empty .left {
    display: flex;
    flex-direction: column;
    width: 424px;
    align-items: flex-start;
    gap: 20px;
    position: relative;
  }
  
  .dashboard-empty .top {
    display: flex;
    width: 424px;
    align-items: flex-start;
    gap: 20px;
    position: relative;
    flex: 0 0 auto;
  }
  
  .dashboard-empty .bar-chart {
    display: flex;
    width: 423.67px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 21px 20px;
    position: relative;
    align-self: stretch;
    background-color: #ffffff;
    border-radius: 12px;
  }
  
  .dashboard-empty .content {
    display: flex;
    flex-direction: column;
    height: 291px;
    align-items: center;
    gap: 24px;
    position: relative;
    flex: 1;
    flex-grow: 1;
  }
  
  .dashboard-empty .frame-wrapper {
    flex-direction: column;
    gap: 12px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
  }
  
  .dashboard-empty .frame-4 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }
  
  .dashboard-empty .text-wrapper-8 {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: "Inter", Helvetica;
    font-weight: 500;
    color: var(--black-60);
    font-size: 16px;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }
  
  .dashboard-empty .key-container {
    position: relative;
    align-self: stretch;
    width: 100%;
    height: 13px;
  }
  
  .dashboard-empty .key {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 21px;
  }
  
  .dashboard-empty .rectangle {
    position: relative;
    width: 8px;
    height: 8px;
    background-color: #4477aa;
    border-radius: 6px;
  }
  
  .dashboard-empty .text-wrapper-9 {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: "Inter", Helvetica;
    font-weight: 400;
    color: #a5a7b1;
    font-size: 11px;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }
  
  .dashboard-empty .key-2 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 304px;
  }
  
  .dashboard-empty .rectangle-2 {
    position: relative;
    width: 8px;
    height: 8px;
    background-color: #cc6677;
    border-radius: 6px;
  }
  
 
  
  .dashboard-empty .overlap-group-2 {
    position: relative;
    width: 172px;
    height: 172px;
    top: 17px;
    left: 17px;
  }
  
  .dashboard-empty .ellipse {
    position: absolute;
    width: 66px;
    height: 43px;
    top: 0;
    left: 21px;
  }
  
  .dashboard-empty .ellipse-2 {
    position: absolute;
    width: 172px;
    height: 172px;
    top: 0;
    left: 0;
  }
  
  .dashboard-empty .middle-right {
    position: absolute;
    width: 743px;
    height: 333px;
    top: 0;
    left: 443px;
    background-color: #ffffff;
    border-radius: 12px;
  }
  
  .dashboard-empty .summary-heading {
    display: inline-flex;
    align-items: center;
    gap: 290px;
    position: absolute;
    top: 15px;
    left: 20px;
  }
  
  .dashboard-empty .frame-5 {
    display: inline-flex;
    align-items: center;
    gap: 21px;
    position: relative;
    flex: 0 0 auto;
  }
  
  .dashboard-empty .p {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: var(--paragraph-1-medium-font-family);
    font-weight: var(--paragraph-1-medium-font-weight);
    color: #44464d;
    font-size: var(--paragraph-1-medium-font-size);
    letter-spacing: var(--paragraph-1-medium-letter-spacing);
    line-height: var(--paragraph-1-medium-line-height);
    white-space: nowrap;
    font-style: var(--paragraph-1-medium-font-style);
  }
  
  .dashboard-empty .group-2 {
    position: absolute;
    width: 641px;
    height: 225px;
    top: 87px;
    left: 51px;
  }
  
  .dashboard-empty .overlap-2 {
    position: absolute;
    width: 623px;
    height: 212px;
    top: 7px;
    left: 18px;
  }
  
  .dashboard-empty .lines {
    position: absolute;
    width: 609px;
    height: 212px;
    top: 0;
    left: 0;
  }
  
  .dashboard-empty .line {
    position: absolute;
    width: 609px;
    height: 1px;
    top: 211px;
    left: 0;
    object-fit: cover;
  }
  
  .dashboard-empty .line-2 {
    position: absolute;
    width: 609px;
    height: 1px;
    top: 158px;
    left: 0;
    object-fit: cover;
  }
  
  .dashboard-empty .line-3 {
    position: absolute;
    width: 609px;
    height: 1px;
    top: 105px;
    left: 0;
    object-fit: cover;
  }
  
  .dashboard-empty .line-4 {
    position: absolute;
    width: 609px;
    height: 1px;
    top: 52px;
    left: 0;
    object-fit: cover;
  }
  
  .dashboard-empty .line-5 {
    position: absolute;
    width: 609px;
    height: 1px;
    top: -1px;
    left: 0;
    object-fit: cover;
  }
  
  .dashboard-empty .group-3 {
    position: absolute;
    width: 591px;
    height: 212px;
    top: 0;
    left: 32px;
  }
  
  .dashboard-empty .overlap-group-wrapper {
    position: absolute;
    width: 45px;
    height: 55px;
    top: 157px;
    left: 0;
  }
  
  .dashboard-empty .div-wrapper {
    position: relative;
    width: 43px;
    height: 55px;
    background-color: #01b8aa;
    border-radius: 5px;
  }
  
  .dashboard-empty .text-wrapper-10 {
    position: absolute;
    top: 21px;
    left: 19px;
    font-family: "Inter", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }
  
  .dashboard-empty .overlap-wrapper {
    position: absolute;
    width: 47px;
    height: 79px;
    top: 133px;
    left: 50px;
  }
  
  .dashboard-empty .overlap-3 {
    position: relative;
    width: 43px;
    height: 79px;
    border-radius: 5px;
  }
  
  .dashboard-empty .bar {
    position: absolute;
    width: 43px;
    height: 79px;
    top: 0;
    left: 0;
    background-color: #374649;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-3 {
    position: relative;
    height: 34px;
    top: 45px;
    background-color: var(--variable-collection-b1);
    border-radius: 5px;
  }
  
  .dashboard-empty .text-wrapper-11 {
    position: absolute;
    top: 56px;
    left: 19px;
    font-family: "Inter", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .text-wrapper-12 {
    position: absolute;
    top: 18px;
    left: 20px;
    font-family: "Inter", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .group-4 {
    position: absolute;
    width: 45px;
    height: 79px;
    top: 133px;
    left: 100px;
  }
  
  .dashboard-empty .overlap-4 {
    position: relative;
    width: 43px;
    height: 79px;
    background-color: #01b8aa;
    border-radius: 5px;
  }
  
  .dashboard-empty .text-wrapper-13 {
    position: absolute;
    top: 33px;
    left: 18px;
    font-family: "Inter", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .group-5 {
    position: absolute;
    width: 48px;
    height: 106px;
    top: 106px;
    left: 149px;
  }
  
  .dashboard-empty .overlap-5 {
    position: relative;
    width: 44px;
    height: 106px;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-wrapper {
    position: absolute;
    width: 44px;
    height: 106px;
    top: 0;
    left: 0;
    background-color: #374649;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-4 {
    position: relative;
    height: 79px;
    top: 27px;
    background-color: var(--variable-collection-b1);
    border-radius: 5px;
  }
  
  .dashboard-empty .text-wrapper-14 {
    position: absolute;
    top: 60px;
    left: 19px;
    font-family: "Inter", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .text-wrapper-15 {
    position: absolute;
    top: 7px;
    left: 20px;
    font-family: "Inter", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .group-6 {
    position: absolute;
    width: 46px;
    height: 106px;
    top: 106px;
    left: 199px;
  }
  
  .dashboard-empty .overlap-6 {
    position: relative;
    width: 44px;
    height: 106px;
    background-color: #01b8aa;
    border-radius: 5px;
  }
  
  .dashboard-empty .text-wrapper-16 {
    position: absolute;
    top: 47px;
    left: 19px;
    font-family: "Inter", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .group-7 {
    position: absolute;
    width: 48px;
    height: 132px;
    top: 80px;
    left: 249px;
  }
  
  .dashboard-empty .overlap-7 {
    position: relative;
    width: 44px;
    height: 132px;
    border-radius: 5px;
  }
  
  .dashboard-empty .bar-2 {
    position: absolute;
    width: 44px;
    height: 132px;
    top: 0;
    left: 0;
    background-color: #374649;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-5 {
    position: relative;
    height: 106px;
    top: 26px;
    background-color: var(--variable-collection-b1);
    border-radius: 5px;
  }
  
  .dashboard-empty .text-wrapper-17 {
    position: absolute;
    top: 73px;
    left: 19px;
    font-family: "Inter", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .group-8 {
    position: absolute;
    width: 45px;
    height: 132px;
    top: 80px;
    left: 299px;
  }
  
  .dashboard-empty .overlap-8 {
    position: relative;
    width: 43px;
    height: 132px;
    background-color: #01b8aa;
    border-radius: 5px;
  }
  
  .dashboard-empty .group-9 {
    position: absolute;
    width: 47px;
    height: 159px;
    top: 53px;
    left: 349px;
  }
  
  .dashboard-empty .overlap-9 {
    position: relative;
    width: 43px;
    height: 159px;
    border-radius: 5px;
  }
  
  .dashboard-empty .bar-3 {
    position: absolute;
    width: 43px;
    height: 159px;
    top: 0;
    left: 0;
    background-color: #374649;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-6 {
    position: relative;
    height: 132px;
    top: 27px;
    background-color: var(--variable-collection-b1);
    border-radius: 5px;
  }
  
  .dashboard-empty .text-wrapper-18 {
    position: absolute;
    top: 87px;
    left: 18px;
    font-family: "Inter", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .text-wrapper-19 {
    position: absolute;
    top: 8px;
    left: 19px;
    font-family: "Inter", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .group-10 {
    position: absolute;
    width: 47px;
    height: 159px;
    top: 53px;
    left: 399px;
  }
  
  .dashboard-empty .group-11 {
    position: absolute;
    width: 46px;
    height: 159px;
    top: 53px;
    left: 448px;
  }
  
  .dashboard-empty .overlap-10 {
    position: relative;
    width: 44px;
    height: 159px;
    background-color: #01b8aa;
    border-radius: 5px;
  }
  
  .dashboard-empty .group-12 {
    position: absolute;
    width: 48px;
    height: 159px;
    top: 53px;
    left: 498px;
  }
  
  .dashboard-empty .overlap-11 {
    position: relative;
    width: 44px;
    height: 159px;
    border-radius: 5px;
  }
  
  .dashboard-empty .bar-4 {
    position: absolute;
    width: 44px;
    height: 159px;
    top: 0;
    left: 0;
    background-color: #374649;
    border-radius: 5px;
  }
  
  .dashboard-empty .text-wrapper-20 {
    position: absolute;
    top: 87px;
    left: 19px;
    font-family: "Inter", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .text-wrapper-21 {
    position: absolute;
    top: 8px;
    left: 20px;
    font-family: "Inter", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .group-13 {
    position: absolute;
    width: 47px;
    height: 212px;
    top: 0;
    left: 548px;
  }
  
  .dashboard-empty .overlap-12 {
    position: relative;
    width: 43px;
    height: 212px;
    border-radius: 5px;
  }
  
  .dashboard-empty .bar-5 {
    position: absolute;
    width: 43px;
    height: 212px;
    top: 0;
    left: 0;
    background-color: #374649;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-7 {
    position: relative;
    height: 159px;
    top: 53px;
    background-color: var(--variable-collection-b1);
    border-radius: 5px;
  }
  
  .dashboard-empty .text-wrapper-22 {
    position: absolute;
    top: 126px;
    left: 19px;
    font-family: "Inter", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .group-14 {
    position: absolute;
    width: 18px;
    height: 225px;
    top: 0;
    left: 0;
  }
  
  .dashboard-empty .text-wrapper-23 {
    position: absolute;
    top: 212px;
    left: 1px;
    font-family: var(--label-2-regular-font-family);
    font-weight: var(--label-2-regular-font-weight);
    color: var(--black-20);
    font-size: var(--label-2-regular-font-size);
    text-align: right;
    white-space: nowrap;
    letter-spacing: var(--label-2-regular-letter-spacing);
    line-height: var(--label-2-regular-line-height);
    font-style: var(--label-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-24 {
    position: absolute;
    top: 159px;
    left: 1px;
    font-family: var(--label-2-regular-font-family);
    font-weight: var(--label-2-regular-font-weight);
    color: var(--black-20);
    font-size: var(--label-2-regular-font-size);
    text-align: right;
    white-space: nowrap;
    letter-spacing: var(--label-2-regular-letter-spacing);
    line-height: var(--label-2-regular-line-height);
    font-style: var(--label-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-25 {
    position: absolute;
    top: 106px;
    left: 0;
    font-family: var(--label-2-regular-font-family);
    font-weight: var(--label-2-regular-font-weight);
    color: var(--black-20);
    font-size: var(--label-2-regular-font-size);
    text-align: right;
    white-space: nowrap;
    letter-spacing: var(--label-2-regular-letter-spacing);
    line-height: var(--label-2-regular-line-height);
    font-style: var(--label-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-26 {
    position: absolute;
    top: 53px;
    left: 1px;
    font-family: var(--label-2-regular-font-family);
    font-weight: var(--label-2-regular-font-weight);
    color: var(--black-20);
    font-size: var(--label-2-regular-font-size);
    text-align: right;
    white-space: nowrap;
    letter-spacing: var(--label-2-regular-letter-spacing);
    line-height: var(--label-2-regular-line-height);
    font-style: var(--label-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-27 {
    position: absolute;
    top: 0;
    left: 1px;
    font-family: var(--label-2-regular-font-family);
    font-weight: var(--label-2-regular-font-weight);
    color: var(--black-20);
    font-size: var(--label-2-regular-font-size);
    text-align: right;
    white-space: nowrap;
    letter-spacing: var(--label-2-regular-letter-spacing);
    line-height: var(--label-2-regular-line-height);
    font-style: var(--label-2-regular-font-style);
  }
  
  .dashboard-empty .group-15 {
    position: absolute;
    width: 377px;
    height: 13px;
    top: 54px;
    left: 183px;
  }
  
  .dashboard-empty .key-wrapper {
    position: absolute;
    width: 169px;
    height: 13px;
    top: 0;
    left: 0;
  }
  
  .dashboard-empty .key-3 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: relative;
  }
  
  .dashboard-empty .rectangle-8 {
    position: relative;
    width: 8px;
    height: 8px;
    background-color: var(--variable-collection-b1);
    border-radius: 6px;
  }
  
  .dashboard-empty .group-16 {
    position: absolute;
    width: 158px;
    height: 13px;
    top: 0;
    left: 219px;
  }
  
  .dashboard-empty .rectangle-9 {
    position: relative;
    width: 8px;
    height: 8px;
    background-color: var(--variable-collection-b2);
    border-radius: 6px;
  }
  
  .dashboard-empty .group-wrapper {
    position: absolute;
    width: 839px;
    height: 423px;
    top: 590px;
    left: 10px;
    background-color: #ffffff;
    border-radius: 12px;
  }
  
  .dashboard-empty .group-17 {
    position: relative;
    width: 832px;
    height: 396px;
    top: 15px;
    left: 10px;
  }
  
  .dashboard-empty .summary-heading-2 {
    display: flex;
    width: 827px;
    align-items: center;
    justify-content: space-around;
    gap: 290px;
    position: absolute;
    top: 0;
    left: 5px;
  }
  
  .dashboard-empty .text-wrapper-28 {
    position: relative;
    width: fit-content;
    font-family: var(--paragraph-1-medium-font-family);
    font-weight: var(--paragraph-1-medium-font-weight);
    color: #44464d;
    font-size: var(--paragraph-1-medium-font-size);
    letter-spacing: var(--paragraph-1-medium-letter-spacing);
    line-height: var(--paragraph-1-medium-line-height);
    white-space: nowrap;
    font-style: var(--paragraph-1-medium-font-style);
  }
  
  .dashboard-empty .icon-2 {
    position: relative;
    width: 120px;
    height: 30px;
    background-color: var(--w-2);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .dashboard-empty .frame-6 {
    display: inline-flex;
    align-items: center;
    gap: 20px;
    position: relative;
    top: 5px;
    left: 12px;
  }
  
  .dashboard-empty .text-wrapper-29 {
    position: relative;
    width: fit-content;
    font-family: "Inter", Helvetica;
    font-weight: 400;
    color: var(--w-1);
    font-size: 14px;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .fi-chevron-down {
    position: relative;
    width: 20px;
    height: 20px;
    margin-top: -175px;
    margin-right: -632.5px;
  }
  
  .dashboard-empty .graph {
    position: absolute;
    width: 813px;
    height: 323px;
    top: 73px;
    left: 0;
  }
  
  .dashboard-empty .overlap-13 {
    position: relative;
    height: 323px;
  }
  
  .dashboard-empty .lines-2 {
    position: absolute;
    width: 741px;
    height: 265px;
    top: 7px;
    left: 72px;
  }
  
  .dashboard-empty .line-6 {
    position: absolute;
    width: 741px;
    height: 1px;
    top: 264px;
    left: 0;
    object-fit: cover;
  }
  
  .dashboard-empty .line-7 {
    position: absolute;
    width: 741px;
    height: 1px;
    top: 211px;
    left: 0;
    object-fit: cover;
  }
  
  .dashboard-empty .line-8 {
    position: absolute;
    width: 741px;
    height: 1px;
    top: 158px;
    left: 0;
    object-fit: cover;
  }
  
  .dashboard-empty .line-9 {
    position: absolute;
    width: 741px;
    height: 1px;
    top: 105px;
    left: 0;
    object-fit: cover;
  }
  
  .dashboard-empty .line-10 {
    position: absolute;
    width: 741px;
    height: 1px;
    top: 52px;
    left: 0;
    object-fit: cover;
  }
  
  .dashboard-empty .line-11 {
    position: absolute;
    width: 741px;
    height: 1px;
    top: -1px;
    left: 0;
    object-fit: cover;
  }
  
  .dashboard-empty .group-18 {
    position: absolute;
    width: 76px;
    height: 278px;
    top: 0;
    left: 0;
  }
  
  .dashboard-empty .text-wrapper-30 {
    top: 265px;
    left: 2px;
    font-weight: var(--label-2-regular-font-weight);
    color: var(--black-20);
    font-size: var(--label-2-regular-font-size);
    text-align: right;
    white-space: nowrap;
    position: absolute;
    font-family: var(--label-2-regular-font-family);
    letter-spacing: var(--label-2-regular-letter-spacing);
    line-height: var(--label-2-regular-line-height);
    font-style: var(--label-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-31 {
    top: 212px;
    left: 0;
    font-weight: var(--label-2-regular-font-weight);
    color: var(--black-20);
    font-size: var(--label-2-regular-font-size);
    text-align: right;
    white-space: nowrap;
    position: absolute;
    font-family: var(--label-2-regular-font-family);
    letter-spacing: var(--label-2-regular-letter-spacing);
    line-height: var(--label-2-regular-line-height);
    font-style: var(--label-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-32 {
    top: 159px;
    left: 0;
    font-weight: var(--label-2-regular-font-weight);
    color: var(--black-20);
    font-size: var(--label-2-regular-font-size);
    text-align: right;
    white-space: nowrap;
    position: absolute;
    font-family: var(--label-2-regular-font-family);
    letter-spacing: var(--label-2-regular-letter-spacing);
    line-height: var(--label-2-regular-line-height);
    font-style: var(--label-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-33 {
    top: 53px;
    left: 0;
    font-weight: var(--label-2-regular-font-weight);
    color: var(--black-20);
    font-size: var(--label-2-regular-font-size);
    white-space: nowrap;
    position: absolute;
    font-family: var(--label-2-regular-font-family);
    letter-spacing: var(--label-2-regular-letter-spacing);
    line-height: var(--label-2-regular-line-height);
    font-style: var(--label-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-34 {
    top: 0;
    left: 0;
    font-weight: var(--label-2-regular-font-weight);
    color: var(--black-20);
    font-size: var(--label-2-regular-font-size);
    white-space: nowrap;
    position: absolute;
    font-family: var(--label-2-regular-font-family);
    letter-spacing: var(--label-2-regular-letter-spacing);
    line-height: var(--label-2-regular-line-height);
    font-style: var(--label-2-regular-font-style);
  }
  
  .dashboard-empty .group-19 {
    position: absolute;
    width: 741px;
    height: 311px;
    top: 12px;
    left: 72px;
  }
  
  .dashboard-empty .group-20 {
    position: absolute;
    width: 45px;
    height: 107px;
    top: 204px;
    left: 0;
  }
  
  .dashboard-empty .bar-6 {
    position: absolute;
    width: 43px;
    height: 92px;
    top: 15px;
    left: 0;
    background-color: #92d050;
    border-radius: 5px;
  }
  
  .dashboard-empty .text-wrapper-35 {
    position: absolute;
    top: 6px;
    left: 5px;
    font-family: "Inter", Helvetica;
    font-weight: 500;
    color: #1a1a1a;
    font-size: 8px;
    text-align: right;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .group-21 {
    position: absolute;
    width: 45px;
    height: 138px;
    top: 173px;
    left: 50px;
  }
  
  .dashboard-empty .bar-7 {
    position: absolute;
    width: 43px;
    height: 123px;
    top: 15px;
    left: 0;
    background-color: #77933c;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-10 {
    position: relative;
    height: 25px;
    top: 98px;
    background-color: var(--x-1);
    border-radius: 5px;
  }
  
  .dashboard-empty .group-22 {
    position: absolute;
    width: 45px;
    height: 176px;
    top: 135px;
    left: 100px;
  }
  
  .dashboard-empty .bar-8 {
    position: absolute;
    width: 43px;
    height: 161px;
    top: 15px;
    left: 0;
    background-color: #ffc000;
    border-radius: 5px;
  }
  
  .dashboard-empty .overlap-group-3 {
    position: relative;
    height: 66px;
    top: 95px;
    background-color: #77933c;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-11 {
    position: relative;
    width: 43px;
    height: 38px;
    top: 28px;
    background-color: var(--x-1);
    border-radius: 5px;
  }
  
  .dashboard-empty .text-wrapper-36 {
    top: 5px;
    left: 4px;
    font-weight: 500;
    color: #1a1a1a;
    font-size: 8px;
    text-align: right;
    position: absolute;
    font-family: "Inter", Helvetica;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .group-23 {
    position: absolute;
    width: 46px;
    height: 149px;
    top: 162px;
    left: 149px;
  }
  
  .dashboard-empty .bar-9 {
    position: absolute;
    width: 44px;
    height: 134px;
    top: 15px;
    left: 0;
  }
  
  .dashboard-empty .overlap-group-4 {
    position: relative;
    height: 134px;
  }
  
  .dashboard-empty .rectangle-12 {
    position: absolute;
    width: 43px;
    height: 134px;
    top: 0;
    left: 0;
    background-color: var(--x-4);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-13 {
    position: absolute;
    width: 44px;
    height: 97px;
    top: 37px;
    left: 0;
    background-color: var(--x-3);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-14 {
    position: absolute;
    width: 44px;
    height: 66px;
    top: 68px;
    left: 0;
    background-color: var(--x-2);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-15 {
    position: absolute;
    width: 44px;
    height: 25px;
    top: 109px;
    left: 0;
    background-color: var(--x-1);
    border-radius: 5px;
  }
  
  .dashboard-empty .group-24 {
    position: absolute;
    width: 46px;
    height: 202px;
    top: 109px;
    left: 199px;
  }
  
  .dashboard-empty .bar-10 {
    position: absolute;
    width: 44px;
    height: 187px;
    top: 15px;
    left: 0;
  }
  
  .dashboard-empty .overlap-group-5 {
    position: relative;
    height: 187px;
  }
  
  .dashboard-empty .rectangle-16 {
    height: 187px;
    background-color: var(--x-5);
    position: absolute;
    width: 43px;
    top: 0;
    left: 0;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-17 {
    position: absolute;
    width: 44px;
    height: 145px;
    top: 42px;
    left: 0;
    background-color: var(--x-4);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-18 {
    position: absolute;
    width: 44px;
    height: 117px;
    top: 70px;
    left: 0;
    background-color: var(--x-3);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-19 {
    width: 44px;
    height: 73px;
    top: 114px;
    position: absolute;
    left: 0;
    background-color: var(--x-2);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-20 {
    position: absolute;
    width: 44px;
    height: 51px;
    top: 136px;
    left: 0;
    background-color: var(--x-1);
    border-radius: 5px;
  }
  
  .dashboard-empty .group-25 {
    position: absolute;
    width: 46px;
    height: 229px;
    top: 82px;
    left: 249px;
  }
  
  .dashboard-empty .bar-11 {
    position: absolute;
    width: 44px;
    height: 214px;
    top: 15px;
    left: 0;
  }
  
  .dashboard-empty .overlap-group-6 {
    position: relative;
    height: 214px;
  }
  
  .dashboard-empty .rectangle-21 {
    height: 214px;
    background-color: var(--x-5);
    position: absolute;
    width: 43px;
    top: 0;
    left: 0;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-22 {
    position: absolute;
    width: 44px;
    height: 192px;
    top: 22px;
    left: 0;
    background-color: var(--x-4);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-23 {
    position: absolute;
    width: 44px;
    height: 149px;
    top: 65px;
    left: 0;
    background-color: var(--x-3);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-24 {
    width: 44px;
    height: 123px;
    top: 91px;
    position: absolute;
    left: 0;
    background-color: var(--x-2);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-25 {
    position: absolute;
    width: 44px;
    height: 66px;
    top: 148px;
    left: 0;
    background-color: var(--x-1);
    border-radius: 5px;
  }
  
  .dashboard-empty .text-wrapper-37 {
    top: 5px;
    left: 6px;
    font-weight: 500;
    color: #1a1a1a;
    font-size: 8px;
    text-align: right;
    position: absolute;
    font-family: "Inter", Helvetica;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .dashboard-empty .group-26 {
    position: absolute;
    width: 45px;
    height: 282px;
    top: 29px;
    left: 299px;
  }
  
  .dashboard-empty .bar-12 {
    position: absolute;
    width: 43px;
    height: 267px;
    top: 15px;
    left: 0;
    background-color: #ff0000;
    border-radius: 5px;
  }
  
  .dashboard-empty .overlap-group-7 {
    position: relative;
    height: 254px;
    top: 13px;
    background-color: #f68100;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-26 {
    position: absolute;
    width: 43px;
    height: 202px;
    top: 52px;
    left: 0;
    background-color: var(--x-3);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-27 {
    width: 43px;
    height: 149px;
    top: 105px;
    position: absolute;
    left: 0;
    background-color: var(--x-2);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-28 {
    position: absolute;
    width: 43px;
    height: 73px;
    top: 181px;
    left: 0;
    background-color: var(--x-1);
    border-radius: 5px;
  }
  
  .dashboard-empty .group-27 {
    position: absolute;
    width: 45px;
    height: 311px;
    top: 0;
    left: 349px;
  }
  
  .dashboard-empty .bar-13 {
    position: absolute;
    width: 43px;
    height: 296px;
    top: 15px;
    left: 0;
    background-color: #ff0000;
    border-radius: 5px;
  }
  
  .dashboard-empty .overlap-group-8 {
    position: relative;
    height: 272px;
    top: 24px;
    background-color: #f68100;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-29 {
    position: absolute;
    width: 43px;
    height: 219px;
    top: 53px;
    left: 0;
    background-color: var(--x-3);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-30 {
    width: 43px;
    height: 145px;
    top: 127px;
    position: absolute;
    left: 0;
    background-color: var(--x-2);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-31 {
    position: absolute;
    width: 43px;
    height: 61px;
    top: 211px;
    left: 0;
    background-color: var(--x-1);
    border-radius: 5px;
  }
  
  .dashboard-empty .group-28 {
    position: absolute;
    width: 45px;
    height: 212px;
    top: 99px;
    left: 399px;
  }
  
  .dashboard-empty .bar-14 {
    position: absolute;
    width: 43px;
    height: 197px;
    top: 15px;
    left: 0;
    background-color: #f68100;
    border-radius: 5px;
  }
  
  .dashboard-empty .overlap-group-9 {
    position: relative;
    height: 161px;
    top: 36px;
    background-color: #ffc000;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-32 {
    width: 43px;
    height: 79px;
    top: 82px;
    position: absolute;
    left: 0;
    background-color: var(--x-2);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-33 {
    position: absolute;
    width: 43px;
    height: 14px;
    top: 147px;
    left: 0;
    background-color: var(--x-1);
    border-radius: 5px;
  }
  
  .dashboard-empty .group-29 {
    position: absolute;
    width: 46px;
    height: 179px;
    top: 132px;
    left: 448px;
  }
  
  .dashboard-empty .bar-15 {
    position: absolute;
    width: 44px;
    height: 164px;
    top: 15px;
    left: 0;
  }
  
  .dashboard-empty .overlap-group-10 {
    position: relative;
    height: 164px;
  }
  
  .dashboard-empty .rectangle-34 {
    height: 164px;
    background-color: var(--x-4);
    position: absolute;
    width: 43px;
    top: 0;
    left: 0;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-35 {
    position: absolute;
    width: 44px;
    height: 117px;
    top: 47px;
    left: 0;
    background-color: var(--x-3);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-36 {
    width: 44px;
    height: 79px;
    top: 85px;
    position: absolute;
    left: 0;
    background-color: var(--x-2);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-37 {
    position: absolute;
    width: 44px;
    height: 61px;
    top: 103px;
    left: 0;
    background-color: var(--x-1);
    border-radius: 5px;
  }
  
  .dashboard-empty .group-30 {
    position: absolute;
    width: 46px;
    height: 207px;
    top: 104px;
    left: 498px;
  }
  
  .dashboard-empty .bar-16 {
    position: absolute;
    width: 44px;
    height: 192px;
    top: 15px;
    left: 0;
  }
  
  .dashboard-empty .overlap-group-11 {
    position: relative;
    height: 192px;
  }
  
  .dashboard-empty .rectangle-38 {
    height: 192px;
    background-color: var(--x-4);
    position: absolute;
    width: 43px;
    top: 0;
    left: 0;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-39 {
    position: absolute;
    width: 44px;
    height: 158px;
    top: 34px;
    left: 0;
    background-color: var(--x-3);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-40 {
    width: 44px;
    height: 134px;
    top: 58px;
    position: absolute;
    left: 0;
    background-color: var(--x-2);
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-41 {
    position: absolute;
    width: 44px;
    height: 72px;
    top: 120px;
    left: 0;
    background-color: var(--x-1);
    border-radius: 5px;
  }
  
  .dashboard-empty .group-31 {
    position: absolute;
    width: 45px;
    height: 257px;
    top: 54px;
    left: 548px;
  }
  
  .dashboard-empty .bar-17 {
    position: absolute;
    width: 43px;
    height: 242px;
    top: 15px;
    left: 0;
    background-color: #ffc000;
    border-radius: 5px;
  }
  
  .dashboard-empty .overlap-group-12 {
    position: relative;
    height: 169px;
    top: 73px;
    background-color: #77933c;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-42 {
    position: relative;
    width: 43px;
    height: 97px;
    top: 72px;
    background-color: var(--x-1);
    border-radius: 5px;
  }
  
  .dashboard-empty .group-32 {
    position: absolute;
    width: 45px;
    height: 212px;
    top: 99px;
    left: 598px;
  }
  
  .dashboard-empty .bar-18 {
    position: absolute;
    width: 43px;
    height: 197px;
    top: 15px;
    left: 0;
    background-color: #ffc000;
    border-radius: 5px;
  }
  
  .dashboard-empty .overlap-group-13 {
    position: relative;
    height: 144px;
    top: 53px;
    background-color: #77933c;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-43 {
    position: relative;
    width: 43px;
    height: 43px;
    top: 101px;
    background-color: var(--x-1);
    border-radius: 5px;
  }
  
  .dashboard-empty .group-33 {
    position: absolute;
    width: 45px;
    height: 154px;
    top: 157px;
    left: 648px;
  }
  
  .dashboard-empty .bar-19 {
    position: absolute;
    width: 43px;
    height: 139px;
    top: 15px;
    left: 0;
    background-color: #ffc000;
    border-radius: 5px;
  }
  
  .dashboard-empty .overlap-group-14 {
    position: relative;
    height: 66px;
    top: 73px;
    background-color: #77933c;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-44 {
    position: relative;
    width: 43px;
    height: 22px;
    top: 44px;
    background-color: var(--x-1);
    border-radius: 5px;
  }
  
  .dashboard-empty .group-34 {
    position: absolute;
    width: 45px;
    height: 97px;
    top: 214px;
    left: 698px;
  }
  
  .dashboard-empty .bar-20 {
    position: absolute;
    width: 43px;
    height: 82px;
    top: 15px;
    left: 0;
    background-color: #ffc000;
    border-radius: 5px;
  }
  
  .dashboard-empty .overlap-group-15 {
    position: relative;
    height: 43px;
    top: 39px;
    background-color: #77933c;
    border-radius: 5px;
  }
  
  .dashboard-empty .rectangle-45 {
    position: relative;
    width: 43px;
    height: 22px;
    top: 21px;
    background-color: var(--x-1);
    border-radius: 5px;
  }
  
  .dashboard-empty .group-35 {
    position: absolute;
    width: 491px;
    height: 13px;
    top: 45px;
    left: 173px;
  }
  
  .dashboard-empty .key-4 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .dashboard-empty .rectangle-46 {
    position: relative;
    width: 8px;
    height: 8px;
    background-color: #92d050;
    border-radius: 6px;
  }
  
  .dashboard-empty .key-5 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 107px;
  }
  
  .dashboard-empty .rectangle-47 {
    position: relative;
    width: 8px;
    height: 8px;
    background-color: #77933c;
    border-radius: 6px;
  }
  
  .dashboard-empty .key-6 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 214px;
  }
  
  .dashboard-empty .rectangle-48 {
    position: relative;
    width: 8px;
    height: 8px;
    background-color: var(--x-3);
    border-radius: 6px;
  }
  
  .dashboard-empty .key-7 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 321px;
  }
  
  .dashboard-empty .rectangle-49 {
    position: relative;
    width: 8px;
    height: 8px;
    background-color: var(--x-4);
    border-radius: 6px;
  }
  
  .dashboard-empty .key-8 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 428px;
  }
  
  .dashboard-empty .rectangle-50 {
    position: relative;
    width: 8px;
    height: 8px;
    background-color: var(--x-5);
    border-radius: 6px;
  }
  
  .dashboard-empty .table {
    position: absolute;
    width: 475px;
    height: 423px;
    top: 590px;
    left: 869px;
    background-color: #ffffff;
    border-radius: 12px;
  }
  
  .dashboard-empty .group-36 {
    position: relative;
    width: 444px;
    height: 382px;
    top: 20px;
    left: 14px;
  }
  
  .dashboard-empty .group-37 {
    position: absolute;
    width: 460px;
    height: 343px;
    top: 39px;
    left: 0;
  }
  
  .dashboard-empty .overlap-group-16 {
    position: absolute;
    width: 443px;
    height: 327px;
    top: -1px;
    left: 0;
  }
  
  .dashboard-empty .line-12 {
    position: absolute;
    width: 2px;
    height: 316px;
    top: 0;
    left: 36px;
  }
  
  .dashboard-empty .line-13 {
    position: absolute;
    width: 2px;
    height: 12px;
    top: 315px;
    left: 81px;
  }
  
  .dashboard-empty .group-38 {
    position: absolute;
    width: 362px;
    height: 12px;
    top: 315px;
    left: 81px;
  }
  
  .dashboard-empty .line-14 {
    position: absolute;
    width: 2px;
    height: 316px;
    top: 0;
    left: 441px;
  }
  
  .dashboard-empty .line-15 {
    position: absolute;
    width: 407px;
    height: 2px;
    top: 315px;
    left: 36px;
  }
  
  .dashboard-empty .group-39 {
    position: absolute;
    width: 414px;
    height: 272px;
    top: 0;
    left: 29px;
  }
  
  .dashboard-empty .group-40 {
    position: absolute;
    width: 30px;
    height: 285px;
    top: 31px;
    left: 0;
  }
  
  .dashboard-empty .text-wrapper-38 {
    position: absolute;
    width: 9px;
    top: 268px;
    left: 7px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-39 {
    position: absolute;
    width: 9px;
    top: 223px;
    left: 7px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-40 {
    position: absolute;
    width: 9px;
    top: 179px;
    left: 7px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-41 {
    position: absolute;
    width: 9px;
    top: 134px;
    left: 7px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-42 {
    position: absolute;
    width: 9px;
    top: 89px;
    left: 7px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-43 {
    position: absolute;
    width: 16px;
    top: 44px;
    left: 0;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    text-align: right;
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-44 {
    position: absolute;
    width: 15px;
    top: 0;
    left: 1px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    text-align: right;
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .ellipse-3 {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 240px;
    left: 55px;
    background-color: var(--w-2);
    border-radius: 7px;
  }
  
  .dashboard-empty .ellipse-4 {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 264px;
    left: 100px;
    background-color: var(--w-2);
    border-radius: 7px;
  }
  
  .dashboard-empty .ellipse-5 {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 240px;
    left: 145px;
    background-color: var(--w-2);
    border-radius: 7px;
  }
  
  .dashboard-empty .ellipse-6 {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 219px;
    left: 190px;
    background-color: var(--w-2);
    border-radius: 7px;
  }
  
  .dashboard-empty .ellipse-7 {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 175px;
    left: 235px;
    background-color: var(--w-2);
    border-radius: 7px;
  }
  
  .dashboard-empty .ellipse-8 {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 151px;
    left: 280px;
    background-color: var(--w-2);
    border-radius: 7px;
  }
  
  .dashboard-empty .ellipse-9 {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 106px;
    left: 325px;
    background-color: var(--w-2);
    border-radius: 7px;
  }
  
  .dashboard-empty .ellipse-10 {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 84px;
    left: 370px;
    background-color: var(--w-2);
    border-radius: 7px;
  }
  
  .dashboard-empty .ellipse-11 {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 60px;
    left: 415px;
    background-color: var(--w-2);
    border-radius: 7px;
  }
  
  .dashboard-empty .vector {
    position: absolute;
    width: 360px;
    height: 207px;
    top: 66px;
    left: 62px;
  }
  
  .dashboard-empty .text-wrapper-45 {
    position: absolute;
    width: 24px;
    top: 326px;
    left: 45px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-46 {
    position: absolute;
    width: 28px;
    top: 326px;
    left: 90px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-47 {
    position: absolute;
    width: 27px;
    top: 326px;
    left: 135px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-48 {
    position: absolute;
    width: 24px;
    top: 326px;
    left: 180px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-49 {
    position: absolute;
    width: 28px;
    top: 326px;
    left: 225px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-50 {
    position: absolute;
    width: 24px;
    top: 326px;
    left: 270px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-51 {
    position: absolute;
    width: 24px;
    top: 326px;
    left: 315px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-52 {
    position: absolute;
    width: 30px;
    top: 326px;
    left: 360px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-53 {
    position: absolute;
    width: 29px;
    top: 326px;
    left: 405px;
    font-family: var(--paragraph-2-regular-font-family);
    font-weight: var(--paragraph-2-regular-font-weight);
    color: var(--w-1);
    font-size: var(--paragraph-2-regular-font-size);
    letter-spacing: var(--paragraph-2-regular-letter-spacing);
    line-height: var(--paragraph-2-regular-line-height);
    font-style: var(--paragraph-2-regular-font-style);
  }
  
  .dashboard-empty .text-wrapper-54 {
    position: absolute;
    top: 0;
    left: 187px;
    font-family: var(--paragraph-1-medium-font-family);
    font-weight: var(--paragraph-1-medium-font-weight);
    color: var(--w-2);
    font-size: var(--paragraph-1-medium-font-size);
    letter-spacing: var(--paragraph-1-medium-letter-spacing);
    line-height: var(--paragraph-1-medium-line-height);
    white-space: nowrap;
    font-style: var(--paragraph-1-medium-font-style);
  }
  